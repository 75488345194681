import React from 'react';
import { Modal, Button } from 'react-bootstrap';
const ExistingSampleModal = (props) => {

    const {
        isOpen,
        setModalOpen,
        handleSubmit,
        handleOverWrite
    } = props;
    const handleOnHide = () => {
        setModalOpen(!isOpen);
    }
    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen}
            onHide={handleOnHide}
        >
            <Modal.Header>
                <Modal.Title>
                    OVERWRITING WARNING
                </Modal.Title>
            </Modal.Header>
            <div className="rename-file-area">
                <Modal.Body className="rename-file-modal-body">
                <p style={{textAlign:'center'}}>
                        THERE ARE IMAGES SAVED UNDER THE LAB/SAMPLETYPE/LABNUMBER. 
                    </p>
                    <p style={{textAlign:'center'}}>
                        YOU CAN OVERWRITE THE IMAGES SAVED OR APPEND A 1 TO THE FILENAME TO PREVENT FROM DELETING NEEDED IMAGES. 
                    </p>
                    <p style={{textAlign:'center'}}>
                        *APPENDED IMAGES WILL SHOW UP IN LIMS.*
                    </p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" size="sm" onClick={(e) => {handleOverWrite(e)}}>OVERWRITE</Button>
                    <Button variant="primary" size="sm" onClick={(e) => {handleSubmit(e)}}>APPEND</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button variant="light" size="sm" onClick={e => handleOnHide(e)}>CANCEL</Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};
export default ExistingSampleModal;