import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import ServiTechLogo from '../../common/logos/servitech-logo.svg';
import { getAccessToken, setAccessToken, getUserEmail, setUserEmail } from '../../auth/accessToken';
import config from '../../config';
import Styled from './style';

const { googleClientId } = config;

const Login = () => {
  const token = getAccessToken();
  const userEmail = getUserEmail();
  const history = useHistory();
  const [loginError, setLoginError] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);


  const handleLoginFailure = () => {
    setAccessToken('');
    setUserEmail('')
    setLoginError(true);
  };

  const handleOnRequest = () => {
    setLoginLoading(true);
  }

  const handleLogin = async response => {
    handleOnRequest();
    const {
      credential = '',
    } = response;
    if (credential) {
      const fetchLogIn = await fetch('https://google-auth-node-login-staging-bjudfyf5za-uc.a.run.app/login', { method: "POST", headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ credential: credential, GOOGLE_CLIENT_ID: googleClientId }) });
      const fetchLogInJson = await fetchLogIn.json();
      let email;
      if (fetchLogInJson && fetchLogInJson.user) {
        email = fetchLogInJson.user.email
        if (email.includes('servitech.com')) {
          const accessToken = fetchLogInJson.user.accessToken;
          setAccessToken(accessToken);
          setUserEmail(email)
          history.push('/sample-capture');
        } else {
          handleLoginFailure();
        }
      }
    } else {
      handleLoginFailure();
    }
  };

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: googleClientId,
        callback: handleLogin
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInButtonDiv"),
        { theme: "outline", size: "large", shape: "rectangle", logo_alignment: "left", text: "signin_with" }  // customization attributes
      );
      window.google.accounts.id.prompt();
    }
    // eslint-disable-next-line
  }, [])

  if (token && userEmail) {
    return <Redirect to="/sample-capture" />;
  }

  if (loginLoading) {
    return (
      <div className="login-wrapper">
        <div className="logging-in-message">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <Styled>
      <div className="login-container">
        <div className="logo">
          <img alt="ServiTech" src={ServiTechLogo} />
        </div>
        <div className="banner">Information System</div>
        {(!loginError || loginError === 'false') && (
          <div className="login-wrapper">
            <div id="googleSignInButtonDiv"></div>
          </div>
        )}
        {(loginError || loginError === 'true') && (
          <div className="login-error">
            Login failed, please try again
          </div>
        )}
      </div>
    </Styled>
  );
};

export default Login;
