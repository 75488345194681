import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADD_SAMPLE_CAPTURE = gql`
  mutation AddSampleCapture(
    $fiscalYear: String
    $lab: String
    $labNumber: Int
    $labNumberEnd: Int
    $photos: [Upload]
    $sampleType: String
    $numberErrorTap: Int
    $existingSampleID: String
    $existingSampleTokens: String
    $overwrite: Boolean
    $overwriteWithinRangeIDs: String
  ) {
    addSampleCapture(
      fiscalYear: $fiscalYear
      lab: $lab
      labNumber: $labNumber
      labNumberEnd: $labNumberEnd
      photos: $photos
      sampleType: $sampleType
      numberErrorTap: $numberErrorTap
      existingSampleID: $existingSampleID
      existingSampleTokens: $existingSampleTokens
      overwrite: $overwrite
      overwriteWithinRangeIDs: $overwriteWithinRangeIDs
    ) {
      error
      ok
    }
  }
`;

export const EDIT_SAMPLE_CAPTURE = gql`
  mutation EditSampleCapture(
    $id: String
    $fiscalYear: String
    $lab: String
    $labNumber: Int
    $photos: [String]
    $photoEdits: [SampleCaptureEdit]
    $sampleType: String
    $userEmail: String
  ) {
    editSampleCapture(
      id: $id
      fiscalYear: $fiscalYear
      lab: $lab
      labNumber: $labNumber
      photos: $photos
      photoEdits: $photoEdits
      sampleType: $sampleType
      userEmail: $userEmail
    ) {
      error
      ok
      sampleCapture {
        id
        fiscalYear
        lab
        labNumber
        photos {
          id
          token
          url
        }
        sampleType
      }
    }
  }
`;
