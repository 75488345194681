import React from 'react';
import PropTypes from 'prop-types';

const XCircle = ({ className, color, lineColor, rotate, size, strokeWidth }) => (
  <svg
    className={className}
    fill={lineColor === 'rgb(255,255,255)' ? color : 'rgb(255,255,255)'}
    height={size}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rotate && { style: { transform: `rotate(${rotate}deg)` } }}
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="15" y1="9" x2="9" y2="15" stroke={lineColor}></line>
    <line x1="9" y1="9" x2="15" y2="15" stroke={lineColor}></line>
  </svg>
);

export default XCircle;

XCircle.defaultProps = {
  className: null,
  color: 'rgb(0,0,0)',
  lineColor: 'rgb(255,255,255)',
  rotate: null,
  size: 16,
  strokeWidth: 2,
};

XCircle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  lineColor: PropTypes.string,
  rotate: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
