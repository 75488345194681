import styled from 'styled-components';

const Styled = styled.div`
  margin: 16px 0;
  min-height: 128px;
  position: relative;
  table {
    tbody {
      > tr > td > div {
        &.fixed-right {
          display: flex;
          justify-content: flex-end;
        }
      }
      &.table-loading {
        opacity: .5;
      }
    }
  }
  .table-loading-message {
    align-items: center;
    display: flex;
    height: calc(100% - 51px);
    justify-content: center;
    position: absolute;
    top: 51px;
    width: 100%;
  }
`;

export default Styled;
