import React, { useState } from 'react';
import { CloseCircle } from '../../common/icons';
import Archive from './archive';
import Capture from './capture';
import Recent from './recent';
import Styled from './style';

const tabs = [{
  key: 'capture',
  label: 'Capture',
}, {
  key: 'recent',
  label: 'Recent',
}, {
  key: 'archive',
  label: 'Archive',
}];

const SampleCature = () => {
  const [activeTab, setActiveTab] = useState('capture');
  const [showModal, setShowModal] = useState({
    blob: '',
    visible: false,
  });

  const handleShowModal = blob => {
    setShowModal({
      blob,
      visible: true,
    });
  };

  const handleCloseModal = () => {
    setShowModal({
      blob: '',
      visible: false,
    });
  };

  return (
    <Styled>
      {showModal.visible && (
        <div className="sample-capture-modal">
          <div
            aria-hidden="true"
            className="close-icon"
            onClick={() => handleCloseModal()}
          >
            <CloseCircle size={40} color="rgb(0,122,255)" />
          </div>
          <div
            aria-hidden="true"
            className="sample-capture-modal-wrap"
            onClick={() => handleCloseModal()}
          >
            <div className="inner">
              <img alt="capture" src={showModal.blob} />
            </div>
          </div>
        </div>
      )}
      <div className="action-pills">
        {tabs.map(({ key, label }) => (
          <div
            aria-hidden="true"
            key={key}
            onClick={() => setActiveTab(key)}
            {...activeTab === key && { className: 'selected' }}
          >
            {label}
          </div>
        ))}
      </div>
      {activeTab === 'capture' && (
        <Capture
          handleCloseModal={handleCloseModal}
          handleShowModal={handleShowModal}
          showModal={showModal}
        />
      )}
      {activeTab === 'recent' && (
        <Recent
          handleCloseModal={handleCloseModal}
          handleShowModal={handleShowModal}
          showModal={showModal}
        />
      )}
      {activeTab === 'archive' && <Archive />}
    </Styled>
  );
};

export default SampleCature;
