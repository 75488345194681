import React from 'react';

const LoadingModal = () => (
  <div className="sample-capture-modal">
    <div className="sample-capture-modal-wrap">
      <div className="inner loading-wrapper">
        <div className="loading">
          Loading...
        </div>
      </div>
    </div>
  </div>
);

export default LoadingModal;
