import React from 'react';
import PropTypes from 'prop-types';
import Styled from './style';

const Button = ({ buttonType, className, children, disabled, onClick, size, type, width }) => {
  const composedClassName = size === 'small'
    ? `${type} ${size} ${className}`.trim()
    : `${type} ${className}`.trim();

  return (
    <Styled
      className={composedClassName}
      disabled={disabled}
      onClick={onClick}
      type={buttonType}
      {...width && { style: { width } }}
    >
      {children}
    </Styled>
  );
};

export default Button;

Button.defaultProps = {
  buttonType: 'button',
  className: '',
  disabled: false,
  onClick: null,
  size: '',
  type: 'default',
  width: null,
};

Button.propTypes = {
  buttonType: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
};
