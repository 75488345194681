import React from 'react';
import PropTypes from 'prop-types';
import { Maximize, Plus, Trash } from '../../../common/icons';
import Webcam from "react-webcam";

const PhotoGrid = props => {
  const {
    blobs,
    handlePhotoDelete,
    handlePhotoEnlarge,
    readOnly,
    videoConstraints,
    webcamRef,
    showAutoPhotoTools,
    fileInput,
    fileInputKey,
    handlePhotoAddToGridFileInput,
    errors
  } = props;

  function doubleclickscreen(e) {
    e.preventDefault()
    e.target.click()
    if (e.touches.length > 1) {
      e.preventDefault()
      e.target.click()
    }
  }

  if (readOnly) {
    return (
      <div className={showAutoPhotoTools ? "sample-capture-image-grid-autopics" : "sample-capture-image-grid"}>
        {blobs.map(({ id, url }) => (
          <div className="capture-wrapper" key={id}>
            <img
              alt="capture"
              aria-hidden="true"
              onClick={() => handlePhotoEnlarge(url)}
              src={`${url}`}
            />
            <div className="icon-wrapper">
              <div
                aria-hidden="true"
                onClick={() => handlePhotoEnlarge(url)}
              >
                <Maximize color="rgb(0,122,255)" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      {showAutoPhotoTools &&
        <div className='auto-grid-container'>
          <div className={showAutoPhotoTools ? "sample-capture-image-grid-autopics" : "sample-capture-image-grid"}>
            {blobs.length > 0 && blobs.map(({ id, blob, url }) => (
              <div className="capture-wrapper" key={id}>
                <img
                  alt="capture"
                  aria-hidden="true"
                  onClick={() => handlePhotoEnlarge(blob)}
                  src={url
                    ? `${url}}`
                    : blob}
                />
                <div className="icon-wrapper">
                  <div
                    aria-hidden="true"
                    onClick={() => handlePhotoEnlarge(blob)}
                  >
                    <Maximize color="rgb(0,122,255)" />
                  </div>
                  <div
                    aria-hidden="true"
                    onClick={() => handlePhotoDelete(id)}
                  >
                    <Trash color="rgb(0,122,255)" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="cam-preview-div" onDoubleClick={(e) => doubleclickscreen(e)} onTouchStart={(e) => doubleclickscreen(e)}>
            <Webcam
              forceScreenshotSourceSize
              height={360}
              width={640}
              audio={false}
              screenshotQuality={1}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </div>
        </div>
      }
      {!showAutoPhotoTools &&
        <div className="sample-capture-image-grid">
          <label
            className={errors && errors.blobs && errors.blobs.error
              ? 'file-upload has-error'
              : 'file-upload'}
            htmlFor="capture-button"
          >
            <div className="icon"><Plus color="rgba(0,0,0,.85)" size={20} /></div>
            <div>Add Photo</div>
            <input
              accept="image/*"
              capture
              hidden
              id="capture-button"
              key={fileInputKey}
              ref={fileInput}
              onChange={e => handlePhotoAddToGridFileInput(e)}
              type="file"
            />
          </label>
          {blobs.length > 0 && blobs.map(({ id, blob, url }) => (
            <div className="capture-wrapper" key={id}>
              <img
                alt="capture"
                aria-hidden="true"
                onClick={() => handlePhotoEnlarge(blob)}
                src={url
                  ? `${url}`
                  : blob}
              />
              <div className="icon-wrapper">
                <div
                  aria-hidden="true"
                  onClick={() => handlePhotoEnlarge(blob)}
                >
                  <Maximize color="rgb(0,122,255)" />
                </div>
                <div
                  aria-hidden="true"
                  onClick={() => handlePhotoDelete(id)}
                >
                  <Trash color="rgb(0,122,255)" />
                </div>
              </div>
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default PhotoGrid;

PhotoGrid.defaultProps = {
  blobs: [],
  errors: {},
  handlePhotoAddToGridFileInput: () => { },
  handlePhotoDelete: () => { },
  handlePhotoEnlarge: () => { },
  fileInput: {},
  fileInputKey: null,
  readOnly: false,
};

PhotoGrid.propTypes = {
  blobs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      blob: PropTypes.string,
      file: PropTypes.oneOfType([
        PropTypes.object,
      ]),
      url: PropTypes.string,
    }),
  ),
  errors: PropTypes.shape({
    blobs: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.string,
    }),
  }),
  handlePhotoAddToGridFileInput: PropTypes.func,
  handlePhotoDelete: PropTypes.func,
  handlePhotoEnlarge: PropTypes.func,
  fileInput: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
  fileInputKey: PropTypes.number,
  readOnly: PropTypes.bool,
};
