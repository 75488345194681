import styled from 'styled-components';

const Styled = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  .login-container {
    background: rgb(255,255,255);
    max-width: 400px;
    padding: 32px 16px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        height: 80px;
      }
    }
    .banner {
      color: rgba(0,0,0,.85);
      display: flex;
      font-size: 24px;
      justify-content: center;
      margin: 16px 0 32px;
    }
    .login-error {
      color: rgb(255,77,79);
      margin-top: 8px;
      text-align: center;
    }
  }
`;

export default Styled;
