import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SAMPLE_CAPTURES_BY_LAB_NUMBERS } from '../../../graphql/queries/sampleCapture';
import ServiTechLogo from '../../../common/logos/servitech-logo.svg';
import Styled from './style';

const SampleView = () => {
  const params = useParams();
  const { data, loading } = useQuery(GET_SAMPLE_CAPTURES_BY_LAB_NUMBERS, {
    fetchPolicy: 'network-only',
    variables: {
      fiscalYear: params.fiscalYear,
      lab: params.lab,
      sampleType: params.sampleType,
      labNumber: parseInt(params.labNumber),
      labNumberEnd: parseInt(params.labNumberEnd)
    },
  });

  return (
    <Styled>
      {loading && (
        <div className="sample-capture-modal">
          <div className="sample-capture-modal-wrap">
            <div className="inner loading-wrapper">
              <div className="loading">
                Loading...
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading
        && data
        && !data.getSampleCapturesByLabNumbers.sampleCaptures.length
        && (
          <>
            <div className="title-wrapper">
              <div className="logo"><img alt="ServiTech" src={ServiTechLogo} /></div>
              <div>ServiTech Lab Sample</div>
            </div>
            <div className="details-wrapper">
              <div>Sample not found</div>
            </div>
          </>
        )}
      {!loading
        && data
        && data.getSampleCapturesByLabNumbers.sampleCaptures.length
        && (
          <>
            {
              data.getSampleCapturesByLabNumbers.sampleCaptures.map((samples) => {
                return (
                  <div key={samples}>
                    {samples.photos.map((x) => <div className="sample-image" key={x.token}>
                      <img alt="sample" src={x.url} />
                    </div>)}
                  </div>
                )
              })
            }
          </>
        )}
    </Styled>
  );
};

export default SampleView;

