import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import bb, { line } from 'billboard.js';
import 'billboard.js/dist/billboard.css';
import { Button } from '../../../../../../common/elements';

const Chart = ({ actionPoints, bindTo, data, handleReset, visible }) => {
  const ref = useRef(null);
  const actionRef = useRef(null);

  useEffect(() => {
    const renderChart = () => {
      ref.current = bb.generate({
        axis: {
          x: {
            tick: {
              centered: true,
              count: 8,
              format: '%-m/%-d',
              outer: false,
            },
            type: 'timeseries',
          },
          y: {
            label: {
              position: 'outer-middle',
              text: 'Avg Scaled Frequency (SF)',
            },
            max: 100,
            min: 0,
            tick: {
              format: x => `${x}%`,
              outer: false,
              show: false,
              values: [0, 20, 40, 60, 80, 100],
            },
          },
        },
        bindto: `#${bindTo}`,
        color: { pattern: ['rgb(224,60,232)'] },
        data: { ...data },
        grid: {
          y: {
            lines: [...actionPoints.map(el => ({ value: Number(el) }))],
            show: true,
          },
        },
        legend: {
          hide: true,
        },
        padding: {
          left: 76,
          right: 12,
        },
        point: {
          show: false,
        },
        size: {
          height: 432,
        },
        tooltip: {
          format: {
            title: d => {
              const options = {
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                month: 'numeric',
                year: '2-digit',
              };

              const formatted = new Intl.DateTimeFormat('en-US', options).format(d);
              return formatted;
            },
            value: value => `${value.toFixed(2)}%`,
          },
        },
        type: line(),
      });
    };

    // guard against uneeded re-renders
    if (visible
      && (actionRef && actionRef.current ? actionRef.current[0] : '') !== actionPoints[0]
      && (actionRef && actionRef.current ? actionRef.current[1] : '') !== actionPoints[1]) {
      actionRef.current = actionPoints;
      renderChart();
    }
  }, [actionPoints, bindTo, data, visible]);

  return (
    <div className="soil-moisture-chart-style">
      <Button
        className="chart-reset-button"
        onClick={() => handleReset(ref)}
        size="small"
        type="default"
      >
        Reset Zoom
      </Button>
      <div id={bindTo} />
    </div>
  );
};

export default Chart;

Chart.defaultProps = {
  visible: true,
};

Chart.propTypes = {
  bindTo: PropTypes.string.isRequired,
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.array.isRequired,
    ).isRequired,
    x: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool,
};
