import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Login } from './components';
import SampleCaptureView from './mfes/sampleCapture/view';
import { getAccessToken, getUserEmail } from './auth/accessToken';
import PrivateRouter from './privateRouter';

const PrivateRoute = ({ component: Component, ...otherProps }) => {
    const token = getAccessToken();
    const userEmail = getUserEmail();

    return (
        <Route
            render={props => (!token || (token && !userEmail) || (!token && !userEmail)
                ? <Redirect to="/login" />
                : <Component {...props} />)}
            {...otherProps}
        />
    );
};

const PublicRouter = () => (
    <BrowserRouter>
        <Switch>
            <Route component={Login} exact path="/login" />
            <PrivateRoute
                component={SampleCaptureView}
                exact
                path="/sample-capture/view/:fiscalYear/:lab/:labNumber/:labNumberEnd/:sampleType"
            />
            <PrivateRoute path="/" component={PrivateRouter} />
            <PrivateRoute component={PrivateRouter} />
            <Redirect to="/" />
        </Switch>
    </BrowserRouter>
);

export default PublicRouter;

