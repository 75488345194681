import styled from 'styled-components';

const Styled = styled.div`
  .table-count-container {
    color: rgba(0,0,0,.85);
    font-size: 12px;
    margin-bottom: 8px;
  }
  .table-search-container {
    align-items: center;
    display: flex;
    /* margin: 8px 0 16px; */
    max-width: 400px;
    .table-search {
      flex: 1;
      margin-right: 8px;
      position: relative;
      > input {
        padding: 4px 30px 4px 12px;
        z-index: 2;
      }
      > div {
        align-items: center;
        cursor: pointer;
        display: none;
        height: 32px;
        position: absolute;
        right: 8px;
        top: 0;
        z-index: 1;
        &.has-search {
          display: flex;
        }
      }
    }
  }
`;

export default Styled;
