import styled from 'styled-components';

const Styled = styled.div`
  .irrigation-calc-container {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    > div {
      :last-child {
        text-align: center;
      }
    }
  }
  .irrigation-calc-label {
    margin-bottom: 4px;
  }
  .irrigation-calc-days {
    align-items: center;
    color: rgba(0,0,0,.85);
    display: flex;
    font-size: 16px;
    height: 30px;
    justify-content: center;
  }
  @media only screen and (max-width: 767px) {
    .irrigation-calc-container {
      grid-template-columns: repeat(1, minmax(0, 1fr));
        > div {
        :last-child {
          text-align: left;
        }
      }
    }
    .irrigation-calc-days {
      height: unset;
      justify-content: flex-start;
    }
  }
`;

export default Styled;
