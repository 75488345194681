import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Textarea } from '../../../../../common/elements';
import Styled from './style';

const getFormattedDate = date => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

const getFormattedTime = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'

  // appending zero in the start if hours less than 10
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes} ${ampm}`;
};

const templates = ['Pivot Pass', 'No Irrigation', 'Start Date', 'Watermark Recommendations', 'Custom'];

const initialPivotPassState = {
  pivotPassMoisture: 'adequate',
  pivotPassTime: 'no',
};

const IrrigationRecommendations = ({ acreInches, deviceType, etInches, handleCalculatorInputChange, visible }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [textareaValue, setTextareaValue] = useState('');

  const [startDate, setStartDate] = useState((new Date()).toLocaleDateString());
  const [startDateMoisture, setStartDateMoisture] = useState('adequate');
  const [startDatePivot, setStartDatePivot] = useState('0.5');
  const [startDateTime, setStartDateTime] = useState('0');

  const [recommendationsView, setRecommendationsView] = useState({
    recommendationsToMap: [],
    view: 'Latest',
  });

  // TODO - rethink this crap
  // const [pivotPassValue, setPivotPassValue] = useState(`Soil moisture is ${initialPivotPassState.pivotPassMoisture} in the root zone. The crop is currently using ${etInches}&quot; of water/day. I recommend making ${initialPivotPassState.pivotPassTime} {acreInches}&quot; pivot passes over the next seven days. Shut down/delay irrigation one day for every {etInches}&quot; of rain.`);
  const [pivotPassAcreInches, setPivotPassAcreInches] = useState(etInches);
  const [pivotPassMoisture, setPivotPassMoisture] = useState(initialPivotPassState.pivotPassMoisture);
  const [pivotPassTime, setPivotPassTime] = useState(initialPivotPassState.pivotPassTime);

  const [watermarkPivotPassTime, setWatermarkPivotPassTime] = useState('no');
  const [watermarkStartDate, setWatermarkStartDate] = useState((new Date()).toLocaleDateString());
  const [watermarkPivotPassAcreInches, setWatermarkAcreInches] = useState(etInches);

  const resetTemplate = () => {
    setPivotPassMoisture('');
    setPivotPassTime('');
    setSelectedTemplate('');
    setTextareaValue('');
  };

  const handleTemplateSelect = e => {
    const { value } = e.target;
    const template = templates.find(el => el === value);

    setSelectedTemplate(template);
    setTextareaValue('');
  };

  const handleTextAreaChange = e => {
    const { value } = e.target;

    if (!value) {
      return resetTemplate();
    }

    if (!selectedTemplate) {
      setSelectedTemplate('Custom');
    }

    return setTextareaValue(value);
  };

  const getTemplateText = templateType => {
    if (templateType === 'Pivot Pass') {
      return `Soil moisture is ${pivotPassMoisture} in the root zone. The crop is currently using ${etInches}" of water/day. I recommend making ${pivotPassTime} ${acreInches}" pivot passes over the next seven days. Shut down/delay irrigation one day for every ${pivotPassAcreInches}" of rain.`;
    }

    if (templateType === 'Start Date') {
      return `Soil moisture is ${startDateMoisture}. Start a ${startDatePivot}" pivot pass on ${startDate}. Shutdown ${startDateTime} days between passes until next check. Delay 1 day per ${acreInches}" of rain.`;
    }

    if (templateType === 'Watermark Recommendations') {
      return `Soil moisture is 73.29% 71.31% 64.17% in the top 3 ft with 0.82in of readily available water. Crop is using 0.25in/day so you have ${0.82 / 0.25} days of available moisture at this time. Make ${watermarkPivotPassTime} pivot passes by ${watermarkStartDate}. Shut down one day for every ${watermarkPivotPassAcreInches}in of rain.`;
    }

    if (templateType === 'No Irrigation') {
      return 'Soil moisture is saturated, no irrigation needed for the next seven days.';
    }

    return textareaValue;
  };

  const handleAddRecommendation = () => {
    const now = new Date();
    const date = `${getFormattedDate(now)} ${getFormattedTime(now)}`;

    const newRecommendation = {
      id: recommendations.length > 0
        ? recommendations[recommendations.length - 1].id + 1
        : 1,
      date,
      text: getTemplateText(selectedTemplate),
      owner: 'Mark Morten',
    };

    const newRecommendations = [
      ...recommendations,
      newRecommendation,
    ];

    setRecommendations(newRecommendations);

    setRecommendationsView({
      ...recommendationsView,
      recommendationsToMap: recommendationsView.view === 'Latest'
        ? newRecommendations.slice(-1)
        : newRecommendations,
    });

    resetTemplate();
  };

  const handleRecommendationsView = view => {
    if (view === 'Latest') {
      return setRecommendationsView({
        ...recommendationsView,
        view: 'Latest',
        recommendationsToMap: recommendations.slice(-1),
      });
    }

    return setRecommendationsView({
      ...recommendationsView,
      view: 'All',
      recommendationsToMap: recommendations,
    });
  };

  // const handleChange = e => setStartDatePivot(e.target.value);

  if (!visible) {
    return null;
  }

  return (
    <Styled>
      {recommendationsView.recommendationsToMap.length > 0 && (
        <>
          <div className="recommendation-view-toggle">
            <div
              aria-hidden="true"
              onClick={() => handleRecommendationsView('Latest')}
              {...recommendationsView.view === 'Latest' && { className: 'selected' }}
            >
              Show Last
            </div>
            <div
              aria-hidden="true"
              onClick={() => handleRecommendationsView('All')}
              {...recommendationsView.view === 'All' && { className: 'selected' }}
            >
              Show All
            </div>
          </div>
          <div className="divider" />
          {recommendationsView.recommendationsToMap.map(el => (
            <React.Fragment key={el.id}>
              <div className="recommendation">
                <div className="owner">{el.owner} - {el.date} <span>Edit</span></div>
                <div className="note">{el.text}</div>
              </div>
              <div className="divider" />
            </React.Fragment>
          ))}
        </>
      )}

      <div className="add-recommendation">
        {recommendationsView.recommendationsToMap.length > 0 && (
          <div className="heading">Add Recommendation</div>
        )}

        {/* TODO - move select to own element */}
        <select
          onChange={e => handleTemplateSelect(e)}
          style={{ width: '216px' }}
          value={selectedTemplate || ''}
        >
          <option disabled value="">Select a template</option>
          {templates.filter(el => (deviceType !== 'Watermark Sensor' ? el !== 'Watermark Recommendations' : el)).map(el => (
            <option key={el}>{el}</option>
          ))}
        </select>
        {/* END TODO - move select to own element */}

        {(!selectedTemplate || (selectedTemplate === 'Custom')) && (
          <div className="textarea-container">
            <Textarea onChange={e => handleTextAreaChange(e)} rows="4" value={textareaValue} />
          </div>
        )}

        {selectedTemplate === 'Pivot Pass' && (
          <div style={{ minHeight: '160px', marginTop: '16px', color: 'rgba(0,0,0,.85)', lineHeight: 1.5715, border: 'solid 1px rgb(216,216,216)', borderRadius: '4px', padding: '4px 12px' }}>
            Soil moisture is
            <select
              onChange={e => setPivotPassMoisture(e.target.value)}
              style={{ margin: '4px 8px', width: '96px' }}
            >
              <option>adequate</option>
              <option>dry</option>
              <option>drying down</option>
              <option>saturated</option>
              <option>wetting up</option>
            </select>
            in the root zone. The crop is currently using {etInches}&quot; of water/day. I recommend making
            <select
              onChange={e => setPivotPassTime(e.target.value)}
              style={{ margin: '4px 8px', width: '96px' }}
            >
              <option>no</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            <Input
              className="calculator-inline"
              onChange={e => handleCalculatorInputChange(e, 'acreInches')}
              value={acreInches || ''}
            />
            &quot; pivot passes over the next 7 days. Shut down/delay irrigation 1 day for every
            <Input
              className="input-inline"
              onChange={e => setPivotPassAcreInches(e.target.value)}
              value={pivotPassAcreInches}
            />
            &quot; of rain.
          </div>
        )}

        {selectedTemplate === 'Start Date' && (
          <div style={{ minHeight: '160px', marginTop: '16px', color: 'rgba(0,0,0,.85)', lineHeight: 1.5715, border: 'solid 1px rgb(216,216,216)', borderRadius: '4px', padding: '4px 12px' }}>
            Soil moisture is
            <select
              onChange={e => setStartDateMoisture(e.target.value)}
              style={{ margin: '4px 8px', width: '96px' }}
            >
              <option>adequate</option>
              <option>dry</option>
              <option>drying down</option>
              <option>saturated</option>
              <option>wetting up</option>
            </select>.
            Start a
            <Input
              className="input-inline"
              onChange={e => setStartDatePivot(e.target.value)}
              value={startDatePivot || ''}
            />
            &quot; pivot pass on
            <Input
              className="input-inline date"
              onChange={e => setStartDate(e.target.value)}
              value={startDate || ''}
            />
            . Shutdown
            <select
              onChange={e => setStartDateTime(e.target.value)}
              style={{ margin: '4px 8px', width: '96px' }}
            >
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            days between passes until next check. Delay 1 day per
            <Input
              className="input-inline"
              // onChange={e => setStartDateDelay(e.target.value)}
              // value={startDateDelay || ''}
              onChange={e => handleCalculatorInputChange(e, 'acreInches')}
              value={acreInches || ''}
            />
            &quot; of rain.
          </div>
        )}

        {selectedTemplate === 'Watermark Recommendations' && (
          <div style={{ minHeight: '160px', marginTop: '16px', color: 'rgba(0,0,0,.85)', lineHeight: 1.5715, border: 'solid 1px rgb(216,216,216)', borderRadius: '4px', padding: '4px 12px' }}>
            Soil moisture is 73.29% 71.31% 64.17% in the top 3 ft with 0.82in of readily available water. Crop is using 0.25in/day so you have {0.82 / 0.25} days of available moisture at this time. Make
            <select
              onChange={e => setWatermarkPivotPassTime(e.target.value)}
              style={{ margin: '4px 8px', width: '96px' }}
            >
              <option>no</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select> pivot passes by
            <Input
              className="input-inline date"
              onChange={e => setWatermarkStartDate(e.target.value)}
              value={watermarkStartDate || ''}
            />. Shut down/delay irrigation 1 day for every
            <Input
              className="input-inline"
              onChange={e => setWatermarkAcreInches(e.target.value)}
              value={watermarkPivotPassAcreInches}
            />
            &quot; of rain.
          </div>
        )}

        {selectedTemplate === 'No Irrigation' && (
          <div style={{ minHeight: '160px', marginTop: '16px', color: 'rgba(0,0,0,.85)', lineHeight: 1.5715, border: 'solid 1px rgb(216,216,216)', borderRadius: '4px', padding: '4px 12px' }}>
            Soil moisture is saturated, no irrigation needed for the next 7 days.
          </div>
        )}
      </div>

      <div className="add-recommendation-button-container">
        <Button disabled={!selectedTemplate || (selectedTemplate === 'Custom' && textareaValue === '')} onClick={() => handleAddRecommendation()} type="primary">Add Recommendation</Button>
        <Button disabled={!selectedTemplate || (selectedTemplate === 'Custom' && textareaValue === '')} onClick={() => resetTemplate()} type="default">Cancel</Button>
      </div>
    </Styled>
  );
};

export default IrrigationRecommendations;

IrrigationRecommendations.defaultProps = {
  visible: true,
};

IrrigationRecommendations.propTypes = {
  acreInches: PropTypes.string.isRequired,
  etInches: PropTypes.string.isRequired,
  handleCalculatorInputChange: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};
