import React from 'react';
import PropTypes from 'prop-types';

const Download = ({ className, color, rotate, size, strokeWidth }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rotate && { style: { transform: `rotate(${rotate}deg)` } }}
  >
    <polyline points="8 17 12 21 16 17"></polyline>
    <line x1="12" y1="12" x2="12" y2="21"></line>
    <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path>
  </svg>
);

export default Download;

Download.defaultProps = {
  className: null,
  color: 'rgb(0,0,0)',
  rotate: null,
  size: 16,
  strokeWidth: 2,
};

Download.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  rotate: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
