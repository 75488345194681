import styled from 'styled-components';

const Styled = styled.div`
  .soil-moisture-chart-select-container {
    margin-bottom: 16px;
  }
  .soil-moisture-chart-set-points-container {
    align-items: flex-end;
    display: flex;
    > div {
      > input {
        margin-right: 8px;
        width: 88px;
      }
    }
  }
  .soil-moisture-chart-style {
    margin-top: 8px;
    min-height: 432px;
    position: relative;
    .chart-reset-button {
      position: absolute;
      right: 88px;
      top: -36px;
    }
  }
  .soil-moisture-chart-toggle-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .soil-moisture-chart-toggles {
      align-items: center;
      display: flex;
      .soil-moisture-chart-quick-select-container {
        align-items: center;
        border: solid 1px rgb(232,232,232);
        border-radius: 4px;
        display: flex;
        margin-right: 12px;
        padding: 4px 12px;
        > div:not(:last-child) {
          margin-right: 12px;
        }
        .selected {
          color: rgb(0,122,255);
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .soil-moisture-chart-toggle-container {
      .soil-moisture-chart-toggles {
        .soil-moisture-chart-quick-select-container {
          display: none;
        }
      }
    }
  }
`;

export default Styled;
