import styled from 'styled-components';

const Styled = styled.div`
  display: flex;
  justify-content: flex-end;
  .pagination-ellipsis {
    align-items: center;
    display: flex;
    height: 32px;
    margin: 0 4px;
  }
`;

export default Styled;
