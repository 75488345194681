import styled from 'styled-components';

const Styled = styled.button`
  align-items: center;
  appearance: none;
  border: solid 1px rgb(0,122,255);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  height: 32px;
  outline: none!important;
  padding: 0 16px;
  :disabled {
    cursor: not-allowed;
    opacity: .75;
  }
  :focus {
    outline: none;
  }
  &.primary {
    background: rgb(0,122,255);
    color: rgb(255,255,255);
    &.pagination-btn {
      justify-content: center;
      margin: 0 4px;
      min-width: 32px;
      padding: 0 4px;
    }
  }
  &.ghost {
    background: transparent;
    color: rgb(0,122,255);
  }
  &.mello {
    background: rgb(248,248,248);
    border-color: rgb(248,248,248);
  }
  &.default {
    background: rgb(255,255,255);
    border-color: rgb(232,232,232);
    color: rgba(0,0,0,.65);
    &.pagination-btn {
      justify-content: center;
      margin: 0 4px;
      min-width: 32px;
      padding: 0 4px;
    }
  }
  &.small {
    height: 28px;
  }
  &.link {
    align-items: flex-end;
    appearance: none;
    background: transparent;
    border: none;
    color: rgb(0,122,255);
    display: flex;
    height: 20px;
    padding: 0;
  }
  &.gsso {
    background: rgb(66,133,244);
    color: rgb(255,255,255);
    font-size: 16px;
    height: 42px;
    padding: 0px 16px 0 56px;
    position: relative;
    text-align: center;
    svg {
      border-radius: 4px;
      bottom: 1px;
      left: 1px;
      position: absolute;
      top: 1px;
    }
   }
`;

export default Styled;
