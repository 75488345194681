export const setAccessToken = (s) => {
  if ( s ) {
    localStorage.setItem('token', s);
  }

  return s;
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('token');

  return accessToken;
}

export const clearAccessToken = () => {
  localStorage.removeItem('token');
}

export const setUserEmail = (s) => {
  if ( s ) {
    localStorage.setItem('userEmail', s);
  }

  return s;
};

export const getUserEmail = () => {
  const userEmail = localStorage.getItem('userEmail');

  return userEmail;
}

export const clearUserEmail = () => {
  localStorage.removeItem('userEmail');
}