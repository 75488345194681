import React, { useRef, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { Filter, Code } from 'react-feather';
import PropTypes from 'prop-types';
import { Card } from '../../../components';
import { Button } from '../../../common/elements';
import { ChevronDown } from '../../../common/icons';
import { EDIT_SAMPLE_CAPTURE } from '../../../graphql/mutations/sampleCapture';
import { GET_SAMPLE_CAPTURES } from '../../../graphql/queries/sampleCapture';
import { uuidv4 } from '../../../utils';
import {
  ErrorModal,
  FormInputs,
  LoadingModal,
  PhotoGrid,
  SuccessModal,
  DeleteModal,
  LabFilterModal,
  SampleTypeFilterModal
} from '../components';
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css'
import { getUserEmail } from '../../../auth/accessToken';
import { useCookies } from 'react-cookie';

const Recent = ({ handleShowModal }) => {
  const [editSampleCapture] = useMutation(EDIT_SAMPLE_CAPTURE, {
    refetchQueries: [{ query: GET_SAMPLE_CAPTURES }]
  });
  const { data, error, loading: queryLoading } = useQuery(GET_SAMPLE_CAPTURES, {
    fetchPolicy: 'network-only',
  });
  const fileInput = useRef();
  const ref = useRef();
  const [blobs, setBlobs] = useState([]);
  const [editRow, setEditRow] = useState('');
  const [errors, setErrors] = useState({});
  const [fileInputKey, setFileInputKey] = useState(1);
  const [formData, setFormData] = useState("");
  const [loading, setLoading] = useState(false);
  const [photoRow, setPhotoRow] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [filteredResources, setFilteredResources] = useState("");
  const [showImages, setShowImages] = useState("");
  const [capArrayChanged, setCapArrayChanged] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [labFilterLabel, setLabFilterLabel] = useState("");
  const [sampleTypeFilterLabel, setSampleTypeFilterLabel] = useState("");
  const [labFilterModal, setLabFilterModal] = useState(false);
  const [sampleTypeFilterModal, setSampleTypeFilterModal] = useState(false);
  const [labButtonLabel, setLabButtonLabel] = useState("");
  const [sampleTypeButtonLabel, sampleTypeLabButtonLabel] = useState("");
  const [filterCombine, setFilterCombine] = useState("")
  const [labFilter, setLabFilter] = useState("")
  const [photoDeleteID, setPhotoDeleteID] = useState("")
  const [editing, setEditing] = useState(false);
  const [usersEmail, setUsersEmail] = useState("");
  const [filterAfterEdit, setFAE] = useState(false);
  const [cookies, setCookie] = useCookies(['user']);


  let s = [];

  const handleCookie = () => {
    setCookie('labFilter', labFilterLabel, { path: '/' });
  }

  function getNestedStrings(obj, level) {
    if (obj) {
      if (level === 1) { s = [] }  // at parent level, start with an empty array
      const valArr = Object.values(obj);  // get array of object property values
      const strArr = valArr.filter((o) => { return (typeof o === 'string') });  // filter array for strings
      const objArr = valArr.filter((o) => { return (typeof o === 'object') });  // filter array for objects
      if (strArr.length > 0) { s = [...s, ...strArr]; };  // append strings to result array
      objArr.forEach((x) => { getNestedStrings(x, level + 1) });  // recurse through objects collecting child strings
      if (level === 1) { return s; };  // at parent level, return the string array
    }
  };

  const handleSearchKeyUp = (event) => {
    event.preventDefault();
    const searchString = event.target.value.toLowerCase();
    if (searchString.length && !sampleTypeFilterLabel.length && !labFilterLabel.length) {
      const res = (showImages.filter((x) => {  // filter resources for object(s) with strings that include searchStr
        return (getNestedStrings(x, 1).some((y) => {
          return (y.toLowerCase().includes(searchString.toLowerCase()))
        }))
      }));
      setFilteredResources(res)  // show result object(s)
    } else if (searchString.length && (sampleTypeFilterLabel.length || labFilterLabel.length)) {
      const res = (filterCombine.filter((x) => {  // filter resources for object(s) with strings that include searchStr
        return (getNestedStrings(x, 1).some((y) => {
          return (y.toLowerCase().includes(searchString.toLowerCase()))
        }))
      }));
      setFilteredResources(res)  // show result object(s)
    } else if (!searchString.length && (sampleTypeFilterLabel.length || labFilterLabel.length)) {
      setFilteredResources(filterCombine)
    } else {
      setFilteredResources(showImages)
    }
  }

  const handleFilterForLab = () => {
    ref.current.value = "";
    setSampleTypeFilterLabel("")
    const filterForLab = labFilterLabel.toLowerCase();
    if (filterForLab.length) {
      const res = (showImages.filter((x) => {
        return (getNestedStrings(x, 1).some((y) => {
          return (y.toLowerCase().includes(filterForLab.toLowerCase()))
        }))
      }));
      setFilteredResources(res)  // show result object(s)
      setFilterCombine(res)
      setLabFilter(res)
    } else {
      setFilteredResources(showImages)
    }
    handleCookie()
  }

  const handleFilterForSampleType = () => {
    ref.current.value = "";
    const filterForSampleType = sampleTypeFilterLabel.toLowerCase();
    if (filterForSampleType.length && !labFilterLabel.length) {
      const res = (showImages.filter((x) => {
        return (getNestedStrings(x, 1).some((y) => {
          return (y.toLowerCase().includes(filterForSampleType.toLowerCase()))
        }))
      }));
      setFilteredResources(res)  // show result object(s)
      setFilterCombine(res)
    } else if ((filterForSampleType.length || filterForSampleType === "") && labFilterLabel.length) {
      const res = (labFilter.filter((x) => {  // filter resources for object(s) with strings that include searchStr
        return (getNestedStrings(x, 1).some((y) => {
          return (y.toLowerCase().includes(filterForSampleType.toLowerCase()))
        }))
      }));
      setFilteredResources(res)  // show result object(s)
      setFilterCombine(res)
    } else {
      setFilteredResources(showImages)
    }
  }

  const handleEditRow = id => {
    if (id === editRow) {
      return setEditRow('');
    }
    const rowToEdit = filteredResources.find(el => el.id === id);
    // shape existing files into blob array with status key
    // status key is used to determine file edits server side
    const blobsToEdit = rowToEdit.photos.map(el => ({
      ...el,
      status: 'KEEP',
    }));
    setFormData(rowToEdit);
    setBlobs(blobsToEdit);
    setPhotoRow('');
    return setEditRow(id);
  };

  const handlePhotoRow = id => {
    if (id === photoRow) {
      return setPhotoRow('');
    }
    setEditRow('');
    return setPhotoRow(id);
  };

  const onChange = e => {
    setEditing(true);
    const { name, value } = e.target;
    if (name === 'labNumber' || name === 'labNumberEnd') {
      setFormData({
        ...formData,
        [name]: parseInt(value),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    delete errors[name];
    setErrors(errors);
  };

  const handlePhotoDelete = async () => {
    const blobId = photoDeleteID;
    setLoading(true);
    setFAE(true);
    const updatedBlobs = blobs.map(el => ({
      ...el,
      status: el.id === blobId ? 'DELETE' : el.status,
    }));


    const variables = {
      ...formData,
      // only upload new files
      photos: blobs.filter(el => !el.url).map(({ file }) => file),
      // send along photo edits for processing on the server
      // this was so wrong ==> photoEdits: updatedBlobs.map(({ id, token }) => ({ id, token, status: 'DELETE' })),
      photoEdits: updatedBlobs.map(({ id, token, status, photoVersion }) => ({ id, token, status, photoVersion })),
      // numberErrorTap,
      userEmail: usersEmail
    };
    await editSampleCapture({
      variables
    });

    setBlobs(updatedBlobs);
    setFileInputKey(fileInputKey + 1);
    setPhotoDeleteID("")
    setLoading(false);

    setShowDeleteModal(false)
    handlePhotoRow(editRow)
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
  };

  const handleConfirmDelete = (id) => {
    setPhotoDeleteID(id);
    setShowDeleteModal(true)
  };

  const handlePhotoAddToGridFileInput = () => {
    if (
      fileInput.current
      && fileInput.current.files
      && fileInput.current.files.length > 0
    ) {
      setEditing(true)
      const file = fileInput.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setBlobs([...blobs, {
          id: uuidv4(),
          blob: reader.result,
          file: fileInput.current.files[0],
          status: 'NEW',
        }]);
      };
      delete errors.blobs;
      setErrors(errors);
    }
  };

  const handleCancel = id => {
    if (id === editRow) {
      return setEditRow('');
    }
    const rowToEdit = filteredResources.find(el => el.id === id);
    // shape existing files into blob array with status key
    // status key is used to determine file edits server side
    const blobsToEdit = rowToEdit.photos.map(el => ({
      ...el,
      status: 'KEEP',
    }));
    setEditing(false);
    setFormData(rowToEdit);
    setBlobs(blobsToEdit);
    setFileInputKey(fileInputKey + 1);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formErrors = {};
    if (!formData.lab) {
      formErrors.lab = { error: true, message: 'Lab is required' };
    }
    if (!formData.sampleType) {
      formErrors.sampleType = { error: true, message: 'Sample Type is required' };
    }
    if (!formData.labNumber) {
      formErrors.labNumber = { error: true, message: 'Lab Number is required' };
    }
    if (blobs.length === 0) {
      formErrors.blobs = { error: true, message: 'At least 1 photo is required' };
    }
    if (Object.keys(formErrors).length > 0) {
      setShowErrorModal(true);
      setErrors(formErrors);
      return;
    }
    setLoading(true);
    setFAE(true);
    const variables = {
      ...formData,
      // only upload new files
      // photos: blobs.filter(el => !el.url).map(({ file }) => file),
      photos: blobs.filter(el => !el.url).map(({ blob }) => blob.split("data:image/jpeg;base64,").pop()),
      // send along photo edits for processing on the server
      photoEdits: blobs.map(({ id, token, status, photoVersion }) => ({ id, token, status, photoVersion })),
      // numberErrorTap,
      userEmail: usersEmail
    };
    const { data: editSampleCaptureData } = await editSampleCapture({
      variables
    });
    const { sampleCapture: rowToEdit } = editSampleCaptureData.editSampleCapture;
    // shape existing files into blob array with status key
    // status key is used to determine file edits server side
    const blobsToEdit = rowToEdit.photos.map(el => ({
      ...el,
      status: 'KEEP',
    }));
    setEditing(false);
    setFormData(rowToEdit);
    setBlobs(blobsToEdit);
    setEditRow(rowToEdit.id);
    setLoading(false);
    setShowSuccess(true);
    handlePhotoRow(editRow)
    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
  };

  const handleCloseError = () => setShowErrorModal(false);
  const handleLabFilter = (filter) => setLabFilterLabel(filter);
  const handleSampleTypeFilter = (filter) => setSampleTypeFilterLabel(filter);
  const handleResetFilter = () => {
    setFilteredResources(showImages)
    setLabFilterLabel("")
    setSampleTypeFilterLabel("")
    ref.current.value = "";
  };

  const openFilterModal = (modal) => {
    switch (modal) {
      case 'labState':
        setLabFilterModal(true);
        break;

      case 'sampleTypeState':
        setSampleTypeFilterModal(true);
        break;

      default:
        setLabFilterModal(false);
        setSampleTypeFilterModal(false);
        break;
    }
  }

  useEffect(() => {
    if (!queryLoading && !error) {
      const { sampleCaptures } = data.getSampleCaptures
      setShowImages(sampleCaptures);
      setCapArrayChanged(true);
    }
  }, [data, queryLoading, error])

  useEffect(() => {
    if (showImages !== filteredResources && capArrayChanged === true) {
      if (!filterAfterEdit) {
        setFilteredResources(showImages)
        setCapArrayChanged(false)
        handleFilterForLab()
      } else {
        setCapArrayChanged(false)
        let filterByLab = [];
        let filteringDone = [];
        let allResources = [];
        if (labFilterLabel.length) {
          const filterForLab = labFilterLabel.toLowerCase();
          const res = (showImages.filter((x) => {
            return (getNestedStrings(x, 1).some((y) => {
              return (y.toLowerCase().includes(filterForLab.toLowerCase()))
            }))
          }));
          allResources = res;  // show result object(s)
          filteringDone = res;
          filterByLab = res;
        }
        if (sampleTypeFilterLabel.length && !labFilterLabel.length) {
          const filterForSampleType = sampleTypeFilterLabel.toLowerCase();
          const res = (showImages.filter((x) => {
            return (getNestedStrings(x, 1).some((y) => {
              return (y.toLowerCase().includes(filterForSampleType.toLowerCase()))
            }))
          }));
          allResources = res  // show result object(s)
          filteringDone = res
        } else if ((sampleTypeFilterLabel.length || sampleTypeFilterLabel === "") && labFilterLabel.length) {
          const filterForSampleType = sampleTypeFilterLabel.toLowerCase();
          const res = (filterByLab.filter((x) => {  // filter resources for object(s) with strings that include searchStr
            return (getNestedStrings(x, 1).some((y) => {
              return (y.toLowerCase().includes(filterForSampleType.toLowerCase()))
            }))
          }));
          allResources = res // show result object(s)
          filteringDone = res
        }
        if (allResources.length) {
          setFilteredResources(allResources);
        } else {
          setFilteredResources(showImages)
        }
        setFilterCombine(filteringDone);
        setLabFilter(filterByLab);
        setFAE(false)
      }
    }
    // eslint-disable-next-line
  }, [showImages, filteredResources, capArrayChanged, labFilterLabel])

  useEffect(() => {
    if (labFilterLabel === "") {
      setLabButtonLabel("All")
    } else {
      setLabButtonLabel(labFilterLabel)
    }
  }, [labFilterLabel])

  useEffect(() => {
    if (sampleTypeFilterLabel === "") {
      sampleTypeLabButtonLabel("All")
    } else {
      sampleTypeLabButtonLabel(sampleTypeFilterLabel)
    }
  }, [sampleTypeFilterLabel])

  useEffect(() => {
    const email = getUserEmail();
    setUsersEmail(email);
    if (cookies.labFilter) {
      if (cookies.labFilter === "All") {
        setLabFilterLabel("")
      } else {
        setLabFilterLabel(cookies.labFilter)
      }
    }
  }, [])

  if (queryLoading) {
    return (
      <LoadingModal />
    )
  }
  if (loading) {
    return (
      <LoadingModal />
    )
  }

  return (
    <>
      {loading && <LoadingModal />}
      {showSuccess && <SuccessModal message="Saved" />}
      {showErrorModal && Object.keys(errors).length > 0 && (
        <ErrorModal
          errors={errors}
          handleCloseError={handleCloseError}
        />
      )}
      <Card>
        <DeleteModal
          isOpen={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          handlePhotoDelete={handlePhotoDelete}
        />
        <LabFilterModal
          isOpen={labFilterModal}
          setModalOpen={setLabFilterModal}
          filter={handleLabFilter}
          filterList={labFilterLabel}
          handleFiltering={handleFilterForLab}
        />
        <SampleTypeFilterModal
          isOpen={sampleTypeFilterModal}
          setModalOpen={setSampleTypeFilterModal}
          filter={handleSampleTypeFilter}
          filterList={sampleTypeFilterLabel}
          handleFiltering={handleFilterForSampleType}
        />
        <Col className="searchbox-for-resources" style={{ marginBottom: "12px" }} >
          <Row>
            <i style={{
              position: "absolute!important",
              opacity: ".99",
              paddingLeft: "12px",
              marginRight: "-32px",
              marginTop: "8px"
            }}>
              <Filter size={18} />
            </i>
            <input
              style={{
                width: "23%",
                borderRadius: "5px",
                border: "1px solid lightgray",
                textAlign: "left",
                textIndent: "36px",
                display: "inline",
                marginLeft: "2px",
                position: "absolute!important"
              }}
              className="input-field"
              name="search"
              type='text'
              ref={ref}
              onKeyUp={handleSearchKeyUp}
              placeholder="Start typing to filter..."
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              className="labStateBtn"
              variant="outline-dark"
              onClick={() => { openFilterModal('labState'); }}
            >
              {labButtonLabel} &nbsp; <Code size={14} style={{ transform: "rotate(90deg)" }} />
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              className="sampleTypeStateBtn"
              variant="outline-dark"
              onClick={() => { openFilterModal('sampleTypeState'); }}
            >
              {sampleTypeButtonLabel} &nbsp; <Code size={14} style={{ transform: "rotate(90deg)" }} />
            </button>
            <button className="reset-filter-btn" onClick={handleResetFilter}>Reset Filters</button>
          </Row>
        </Col>
        <div className="table-head">
          <div>Lab Number</div>
          <div>Lab</div>
          <div>Sample Type</div>
          <div></div>
        </div>
        {filteredResources.length === 0 &&
          <div style={{ textAlign: 'center' }}>No recent sample captures</div>
        }
        {filteredResources && filteredResources.map(el => (
          <React.Fragment key={el.id}>
            <div className={photoRow === el.id
              ? 'table-row has-extra'
              : 'table-row'}
            >
              <div>{el.labNumber}</div>
              <div>{el.lab}</div>
              <div>{el.sampleType}</div>
              <div className="action-col">
                <div
                  aria-hidden="true"
                  className="photo-action"
                  onClick={() => handlePhotoRow(el.id)}
                >
                  {el.photos.length === 1
                    ? `${photoRow === el.id ? 'Hide' : 'Show'} ${el.photos.length} Photo`
                    : `${photoRow === el.id ? 'Hide' : 'Show'} ${el.photos.length} Photos`}
                  <ChevronDown color="rgb(0,122,255)" {...photoRow === el.id && { rotate: 180 }} />
                </div>
                <div className="divider" />
                <div
                  aria-hidden="true"
                  className="photo-action"
                  onClick={() => handleEditRow(el.id)}
                >
                  Edit
                </div>
              </div>
            </div>
            {photoRow === el.id && (
              <div className="table-row-extra">
                <PhotoGrid
                  blobs={el.photos}
                  handlePhotoEnlarge={handleShowModal}
                  readOnly
                />
              </div>
            )}
            {editRow === el.id && (
              <form
                className="table-row-extra"
                onSubmit={e => handleSubmit(e)}
              >
                <FormInputs
                  errors={errors}
                  formData={formData}
                  onChange={onChange}
                />
                <div className="divider" />
                <PhotoGrid
                  blobs={blobs.filter(blob => blob.status !== 'DELETE')}
                  errors={errors}
                  handlePhotoAddToGridFileInput={handlePhotoAddToGridFileInput}
                  handlePhotoDelete={handleConfirmDelete}
                  handlePhotoEnlarge={handleShowModal}
                  fileInput={fileInput}
                  fileInputKey={fileInputKey}
                />
                <div className="edit-sample-capture-buttons">
                  <Button onClick={() => handleCancel(el.id)}>Cancel</Button>

                  {editing &&
                    <Button buttonType="submit" type="primary">Save</Button>
                  }
                  {!editing &&
                    <Button type="secondary">Save</Button>
                  }

                </div>
              </form>
            )}
          </React.Fragment>
        ))}
      </Card>
    </>
  );
};
export default Recent;
Recent.propTypes = {
  handleShowModal: PropTypes.func.isRequired,
};