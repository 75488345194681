import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import App from './app';
import GlobalStyle from './style';
import client from './apolloClient';
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <ApolloProvider client={client}>
                <GlobalStyle />
                <App />
            </ApolloProvider>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
