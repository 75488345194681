import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header } from './components';
import { SampleCapture } from './mfes';

const routes = [
    { component: SampleCapture, route: '/' },
    { component: SampleCapture, route: '/sample-capture' },
];

const Router = () => (
    <>
        <Header />
        <div className="content">
            <Switch>
                {routes.map(({ route }) => (
                    <Redirect
                        exact
                        from={`${route}/`}
                        key={route}
                        strict
                        to={route}
                    />
                ))}
                {routes.map(({ component, route }) => (
                    <Route
                        component={component}
                        exact
                        key={route}
                        path={route}
                    />
                ))}
            </Switch>
        </div>
    </>
);

export default Router;
