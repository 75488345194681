import React from 'react';
import PropTypes from 'prop-types';
import Styled from './style';

const EtSummary = ({ visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <Styled>
      <div className="et-summary">
        <div className="et-summary-label">Last 3</div>
        <div className="et-summary-data">0.25&quot;</div>
      </div>
      <div className="et-summary">
        <div className="et-summary-label">9/10</div>
        <div className="et-summary-data">0.25&quot;</div>
      </div>
      <div className="et-summary today">
        <div className="et-summary-label">Today</div>
        <div className="et-summary-data">0.25&quot;</div>
        <div className="et-summary-callout"></div>
      </div>
      <div className="et-summary">
        <div className="et-summary-label">9/12</div>
        <div className="et-summary-data">0.25&quot;</div>
      </div>
      <div className="et-summary">
        <div className="et-summary-label">Next 3</div>
        <div className="et-summary-data">0.25&quot;</div>
      </div>
      <div className="et-summary">
        <div className="et-summary-label">Next 7</div>
        <div className="et-summary-data">0.25&quot;</div>
      </div>
    </Styled>
  );
};

export default EtSummary;

EtSummary.defaultProps = {
  visible: true,
};

EtSummary.propTypes = {
  visible: PropTypes.bool,
};
