import styled from 'styled-components';

const Styled = styled.div`
  .recommendation {
    margin-bottom: 16px;
    .owner {
      margin-bottom: 8px;
      span {
        color: rgb(0,122,255);
        margin-left: 12px;
      }
    }
    .note {
      color: rgba(0,0,0,.85);
      line-height: 1.5715;
    }
  }
  /* .divider {
    background: rgb(232,232,232);
    height: 1px;
    margin: 16px 0;
    width: 100%;
  } */
  .add-recommendation {
    .heading {
      color: rgba(0,0,0,.85);
      font-size: 16px;
      margin-bottom: 16px;
    }
    .textarea-container {
      display: flex;
      margin-top: 16px;
      width: 100%;
      textarea {
        display: flex;
        width: 100%;
      }
    }
  }
  .add-recommendation-button-container {
    display: flex;
    margin-top: 16px;
    > button {
      :not(:first-child) {
        margin-left: 8px;
      }
    }
  }
  .recommendation-view-toggle {
    align-items: center;
    border: solid 1px rgb(232,232,232);
    border-radius: 4px;
    display: flex;
    width: 146px;
    > div {
      cursor: pointer;
      &.selected {
        color: rgb(0,122,255);
      }
      :first-child {
        padding: 4px 6px 4px 12px;
      }
      :last-child {
        padding: 4px 12px 4px 6px;
      }
    }
  }
`;

export default Styled;
