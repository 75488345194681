import React from 'react';
import PropTypes from 'prop-types';

const Maximize = ({ className, color, rotate, size, strokeWidth }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rotate && { style: { transform: `rotate(${rotate}deg)` } }}
  >
    <polyline points="15 3 21 3 21 9"></polyline>
    <polyline points="9 21 3 21 3 15"></polyline>
    <line x1="21" y1="3" x2="14" y2="10"></line>
    <line x1="3" y1="21" x2="10" y2="14"></line>
  </svg>
);

export default Maximize;

Maximize.defaultProps = {
  className: null,
  color: 'rgb(0,0,0)',
  rotate: null,
  size: 16,
  strokeWidth: 2,
};

Maximize.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  rotate: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
