import React from 'react';
import PropTypes from 'prop-types';
import { Check } from '../../../common/icons';

const SuccessModal = ({ message }) => (
  <div className="success">
    <div><Check color="rgb(0,200,5)" size={20} /> {message}</div>
  </div>
);

export default SuccessModal;

SuccessModal.propTypes = {
  message: PropTypes.string.isRequired,
};
