import React from 'react';
import PropTypes from 'prop-types';
import { StyledInput, StyledInputLabel } from './style';

const CustomInputLabel = ({ children, error, label, preSelect, required }) => {
  const composedClassName = [
    'label-wrapper',
    required ? 'required' : null,
    error ? 'has-error' : null,
  ];

  const className = composedClassName
    .filter(el => el !== null)
    .join(' ')
    .trim();

  const PreSelectRender = (
    <div className="label-preselect-wrapper">
      <div className="label-preselect">
        {preSelect}
      </div>
      {children}
    </div>
  );

  return (
    <StyledInputLabel>
      <div className={className}>
        <label htmlFor={label}>{label}</label>
      </div>
      {preSelect ? PreSelectRender : children}
    </StyledInputLabel>
  );
};

const CustomInput = ({ className, name, type, onChange, onKeyUp, value }) => {

  return (
    <StyledInput
      name={name}
      onChange={func => onChange(func)}
      type={type || 'text'}
      value={value}
      {...className && { className }}
      {...onKeyUp && { onKeyUp: func => onKeyUp(func) }}
    />
  );
};

const Input = ({ className, error, inputType, label, name, onChange, onKeyUp, placeholder, preSelect, rules, value }) => {
  const composedClassName = [
    className || null,
    error ? 'has-error' : null,
  ];

  const InputRender = (
    <CustomInput
      name={name}
      onChange={func => onChange(func)}
      placeholder={placeholder}
      type={inputType}
      value={value}
      {...composedClassName.length > 0 && { className: composedClassName.join(' ').trim() }}
      {...onKeyUp && { onKeyUp: func => onKeyUp(func) }}
    />
  );

  const InputWithLabelRender = (
    <CustomInputLabel
      error={error}
      label={label}
      preSelect={preSelect}
      required={rules && rules.required}
    >
      {InputRender}
    </CustomInputLabel>
  );

  return label ? InputWithLabelRender : InputRender;
};

export default Input;

Input.defaultProps = {
  className: '',
  error: false,
  inputType: 'text',
  label: '',
  name: '',
  onChange: null,
  onKeyUp: null,
  placeholder: '',
  preSelect: null,
  value: '',
};

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  inputType: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  preSelect: PropTypes.node,
  rules: PropTypes.shape({
    message: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
