import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  margin: 32px 16px;
  text-align: center;
`;

const Error = ({ children }) => (
  <Styled>
    {children}
  </Styled>
);

export default Error;
