import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Styled = styled.svg`
  animation: loadingCircle 1s linear infinite;

  @keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
`;

const Loading = ({ className, color, rotate, size, strokeWidth }) => (
  // <svg
  //   className={className}
  //   fill="none"
  //   height={size}
  //   stroke={color}
  //   strokeLinecap="round"
  //   strokeLinejoin="round"
  //   strokeWidth={strokeWidth}
  //   viewBox="0 0 24 24"
  //   width={size}
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...rotate && { style: { transform: `rotate(${rotate}deg)` } }}
  // >
  //   <line x1="18" y1="6" x2="6" y2="18"></line>
  //   <line x1="6" y1="6" x2="18" y2="18"></line>
  // </svg>
  <Styled viewBox="0 0 1024 1024" width="14px" height="14px" fill="currentColor" aria-hidden="true">
    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
  </Styled>
);

export default Loading;

Loading.defaultProps = {
  className: null,
  color: 'rgb(0,0,0)',
  rotate: null,
  size: 16,
  strokeWidth: 2,
};

Loading.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  rotate: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
