import { gql } from '@apollo/client';

export const GET_SAMPLE_CAPTURE = gql`
  query GetSampleCapture(
    $fiscalYear: String
    $lab: String
    $labNumber: Int
    $sampleType: String
  ) {
    getSampleCapture(
      fiscalYear: $fiscalYear
      lab: $lab
      labNumber: $labNumber
      sampleType: $sampleType
    ) {
      ok
      sampleCapture {
      id
      fiscalYear
      lab
      labNumber
      photos {
        id
        token
        url
      }
      sampleType
      }
    }
  }
`;
export const GET_SAMPLE_CAPTURES = gql`
  query GetSampleCaptures {
    getSampleCaptures {
      sampleCaptures {
        id
        fiscalYear
        lab
        labNumber
        photos {
          id
          token
          url
          photoVersion
          s3ObjectUrl
        }
        sampleType
      }
    }
  }
`;
export const GET_SAMPLE_CAPTURES_BY_LAB_NUMBERS = gql`
  query getSampleCapturesByLabNumbers(
    $fiscalYear: String
    $lab: String
    $labNumber: Int
    $sampleType: String
    $labNumberEnd: Int
  ) {
    getSampleCapturesByLabNumbers(
      fiscalYear: $fiscalYear
      lab: $lab
      labNumber: $labNumber
      sampleType: $sampleType
      labNumberEnd: $labNumberEnd
    ) {
      ok
      sampleCaptures {
      id
      fiscalYear
      lab
      labNumber
      sampleType
      photos {
        id
        token
        url
      }
      }
    }
  }
`;