import React, { useState } from 'react';
import CpChartData from '../../../../../../mockData/cp-full.json';
import WatermarkChartData from '../../../../../../mockData/watermark-full.json';
import Chart from './chart';

// temp mock data
const xData = CpChartData.chart.series[0].data.map(el => el[0]);
const wmxData = WatermarkChartData.chart.series[0].data.map(el => el[0]);

const rain = ['Rain (Hourly)'];

const allCpData = CpChartData.chart.series.map(el => {
  const arr = [];

  arr.push(el.name);
  el.data.forEach(elem => {
    if (el.name === '4"') {
      if (elem[0] === 1594468838000) {
        rain.push(0.3);
      } else if (elem[0] === 1594555238000) {
        rain.push(0.02);
      } else {
        rain.push(0);
      }
    }

    arr.push(elem[1]);
  });

  return arr;
});

const allWmData = WatermarkChartData.chart.series.map(el => {
  const arr = [];

  arr.push(el.name);
  el.data.forEach(elem => {
    if (el.name === '12"') {
      if (elem[0] === 1591784838000) {
        rain.push(0.3);
      } else if (elem[0] === 1591790438000) {
        rain.push(0.02);
      } else {
        rain.push(0);
      }
    }

    arr.push(elem[1]);
  });

  return arr;
});

const formattedData = deviceType => {
  const cpAxes = {
    '4"': 'y',
    '8"': 'y',
    '12"': 'y',
    '16"': 'y',
    '24"': 'y',
    '32"': 'y',
    'Rain (Hourly)': 'y2',
    'Rain (Daily)': 'y2',
  };

  const cpXs = {
    '4"': 'x',
    '8"': 'x',
    '12"': 'x',
    '16"': 'x',
    '24"': 'x',
    '32"': 'x',
    'Rain (Daily)': 'x2',
    'Rain (Hourly)': 'x',
  };

  const wmAxes = {
    '6"': 'y',
    '12"': 'y',
    '24"': 'y',
    '36"': 'y',
    'Rain (Hourly)': 'y2',
    'Rain (Daily)': 'y2',
  };

  const wmXs = {
    '6"': 'x',
    '12"': 'x',
    '24"': 'x',
    '36"': 'x',
    'Rain (Daily)': 'x2',
    'Rain (Hourly)': 'x',
  };

  return {
    axes: deviceType === 'Watermark Sensor' ? wmAxes : cpAxes,
    colors: {
      'Rain (Daily)': 'rgb(190,222,252)',
      'Rain (Hourly)': 'rgb(70,149,244)',
    },
    columns: [
      ['x', ...deviceType === 'Watermark Sensor' ? wmxData : xData],
      ['x2', 1594555248000],
      ...deviceType === 'Watermark Sensor' ? allWmData : allCpData,
      rain,
      ['Rain (Daily)', 0.32],
    ],
    xs: deviceType === 'Watermark Sensor' ? wmXs : cpXs,
  };
};

const resetSubchart = inst => inst.flush();
const handleReset = ref => resetSubchart(ref.current);

const SensorReadingChart = ({
  bindTo,
  deviceType,
  visible,
}) => {
  const [moisturePoints/* , setMoisturePoints */] = useState([50, 65]);

  return (
    <Chart
      actionPoints={moisturePoints}
      bindTo={bindTo}
      data={formattedData(deviceType)}
      handleReset={handleReset}
      visible={visible}
    />
  );
};

export default SensorReadingChart;
