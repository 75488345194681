import styled from 'styled-components';

const Styled = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  .et-summary {
    text-align: center;
  }
  .et-summary-label {
    border: solid 1px rgb(232,232,232);
    padding: 4px;
  }
  .et-summary-data {
    border: solid 1px rgb(232,232,232);
    border-top: none;
    color: rgba(0,0,0,.85);
    font-size: 16px;
    padding: 4px;
  }
  .et-summary.today {
    position: relative;
  }
  .et-summary.today .et-summary-label {
    color: rgba(0,0,0,.85);
  }
  .et-summary.today .et-summary-callout {
    border: solid 2px rgb(0,122,255);
    bottom: 0;
    left: 0;
    position: absolute;
    right: -1px;
    top: 0;
  }
  .et-summary:not(:last-child) .et-summary-label,
  .et-summary:not(:last-child) .et-summary-data {
    border-right: none;
  }
`;

export default Styled;
