import styled from 'styled-components';

const Styled = styled.div`
  .card {
    margin-bottom: 80px;
    padding: 16px;
  }
  .sample-capture-action-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    > div {
      margin-right: 16px;
      width: 100%;
      :last-child {
        margin-right: 0;
      }
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      > div {
        margin-bottom: 16px;
        margin-right: 0;
        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  label {
    color: rgba(0,0,0,.85);
    &.file-upload {
      align-items: center;
      background: rgb(250,250,250);
      border: 1px dashed rgb(216,216,216);
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 249px;
      justify-content: center;
      width: 100%;
      &.has-error {
        border-color: rgb(255,77,79);
      }
      .icon {
        margin-bottom: 2px;
      }
    }
  }
  .success {
    display: flex;
    left: 0;
    position: fixed;
    right: 0;
    top: 16px;
    z-index: 3;
    > div {
      align-items: center;
      background: rgb(255,255,255);
      border-radius: 4px;
      box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
      color: rgb(0,0,0,.85);
      display: flex;
      margin: auto;
      padding: 8px;
      svg {
        margin-right: 4px;
      }
    }
  }
  .sample-capture-button-container {
    align-items: center;
    background: rgb(255,255,255);
    bottom: 0;
    box-shadow: rgba(0, 21, 41, 0.08) 0px -1px 3px 0px;
    display: flex;
    height: 64px;
    left: 0;
    position: fixed;
    right: 0;
    .sample-capture-buttons {
      display: flex;
      padding: 0 16px;
      width: 100%;
      > button {
        height: 40px;
        justify-content: center;
        width: 100%;
        :first-child {
          margin-right: 16px;
          width: 25%;
        }
      }
    }
  }
  select {
    height: 40px;
    option:disabled {
    color: #000;
    font-weight: bold;
}
  }
  input {
    height: 40px;
  }
  .capture-wrapper {
    background: rgb(250,250,250);
    display: flex;
    flex-direction: column;
    img {
      border: solid 1px rgb(232,232,232);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      height: 200px;
      object-fit: contain;
      width: 100%;
    }
    .icon-wrapper {
      background: rgb(250,250,250);
      border: solid 1px rgb(232,232,232);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: none;
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      width: 100%;
      > div {
        align-items: center;
        border-right: solid 1px rgb(232,232,232);
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 8px 0;
        :last-child {
          border-right: none;
        }
      }
    }
  }
  .lock-wrapper {
    display: flex;
    > button {
      height: 35px;
      justify-content: center;
      padding: 0 8px;
      width: 100%;
      :first-child:not(:only-child) {
        margin-right: 16px;
      }
    }
    &.with-input {
      margin-top: 12px;
    }
  }
  .sample-capture-lab-number-wrapper {
    display: flex;
    > div {
      flex: 1;
      :first-child:not(:only-child) {
        margin-right: 12px;
      }
    }
  }
  .sample-capture-modal {
    background: rgba(0,0,0,.45);
    bottom: 0;
    left: 0;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
    .sample-capture-modal-wrap {
      bottom: 0;
      left: 0;
      outline: 0;
      overflow: auto;
      padding: 48px;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1000;
      .inner {
        position: relative;
        &.loading-wrapper {
          height: 100%;
          .loading {
            align-items: center;
            color: rgb(255,255,255);
            display: flex;
            font-size: 20px;
            height: 100%;
            justify-content: center;
          }
        }
      }
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
    .close-icon {
      position: absolute;
      right: 28px;
      top: 28px;
      z-index: 1002;
    }
  }
  .sample-capture-image-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media only screen
    and (min-device-width : 768px)
    and (max-device-width : 1024px)
    and (orientation : portrait) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
    .sample-capture-image-grid-autopics {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;
    @media only screen
    and (min-device-width : 768px)
    and (max-device-width : 1024px)
    and (orientation : portrait) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media only screen and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  .select-wrapper {
    &.has-error {
      label {
        color: rgb(255,77,79);
      }
      select {
        border-color: rgb(255,77,79);
        :focus {
          border-color: rgb(255,77,79);
          outline: 0;
        }
      }
    }
  }
  .error {
    background: rgb(255,255,255);
    border-radius: 4px;
    bottom: 80px;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    padding: 16px;
    position: fixed;
    right: 16px;
    z-index: 1000;
    .title-wrapper {
      align-items: center;
      display: flex;
      > div {
        color: rgb(0,0,0,.85);
        margin-left: 16px;
      }
      > svg {
        :last-child {
          margin-left: auto;
          margin-top: -4px;
        }
      }
    }
    ul {
      margin-bottom: 4px;
      margin-right: 24px;
      margin-top: 8px;
      padding-left: 58px;
    }
  }
  .table-head,
  .table-row {
    align-items: center;
    border-bottom: 1px solid rgb(240,240,240);
    display: flex;
    > div {
      padding: 16px;
      width: 25%;
    }
  }
  .table-head {
    background: rgb(250,250,250);
    color: rgb(0,0,0);
  }
  .table-row {
    .action-col {
      align-items: center;
      color: rgb(0,122,255);
      display: flex;
      justify-content: flex-end;
      .photo-action {
        align-items: center;
        cursor: pointer;
        display: flex;
        svg {
          margin-left: 4px;
        }
      }
      .divider {
        background: rgb(216,216,216);
        height: 16px;
        margin: 0 8px;
        width: 1px;
      }
    }
  }
  .table-row-extra {
    border-bottom: 1px solid rgb(240,240,240);
    padding: 16px;
    .table-row-extra-heading {
      color: rgb(0,0,0);
    }
  }
  .table-row-none {
    padding: 16px 16px 0;
    text-align: center;
    width: 100%;
  }
  .edit-sample-capture-buttons {
    align-items: center;
    display: flex;
    margin-top: 16px;
    width: 100%;
    > button {
      height: 40px;
      justify-content: center;
      width: 100%;
      :first-child {
        margin-right: 16px;
        width: 25%;
      }
    }
  }
  .archive-search-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  .green{
    background-color: green;
    color: white;
    border: none;
  }
  .red {
    background-color: red;
    color: white;
    border: none;
  }
  .capture-preview{
   float: right;
  }
  .action-pills{
    height:20px;
    vertical-align: middle;
  }
  .auto-grid-container{
    display: grid;
    grid-gap: 5px;
    grid-template-columns: auto auto;
    @media only screen
    and (min-device-width : 768px)
    and (max-device-width : 1024px)
    and (orientation : portrait) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media only screen and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  .cam-preview-div{
    text-align: right
  }
  .auto-photo-controls-div{
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-bottom: 10px;
  }
  .auto-control {
    margin-right: 8px;
    margin-left: 8px;
    height: 40px;
    width: 200px;
    justify-content: center;
  }
  .seconds-label{
    color: grey;
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 30px;
  }
  .seconds-input{
    position: relative;
    align-items: center;
  }
  .advance-options-button {
    border: none;
  }
  .capture-image-button{
    margin-left: 20px;
  }
  .auto-picture-notification{
    text-align: right;
    color: blue;
    margin-right: 150px;
  }
  .range-toggle-button{
    width: auto;
    margin-right: 10px;
    justify-content: center;
  }
  .select-all-checkbox-container{
    text-align: right;
  }
  .select-all-checkbox{
    vertical-align: middle;
  }
  .select-all-label {
    padding-right: 8px;
  }
  .print-button-div{
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .print-button{
    margin-right: 5px;
    margin-top: 5px;
  }
  .extra-margin {
    margin-top 10px;
  }
`;

export default Styled;
