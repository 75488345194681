import React, { useState } from 'react';
import AvgTrendLineData from '../../../../../../mockData/avg-trend-line-full.json';
import Chart from './chart';

// temp mock data
const xerData = AvgTrendLineData.chart.series[0].data.map(el => el[0]);
const allAvgData = AvgTrendLineData.chart.series.map(el => {
  const arr = [];

  arr.push(el.name);
  el.data.forEach(elem => {
    arr.push(elem[1]);
  });

  return arr;
});

const ttemp = {
  columns: [
    ['x', ...xerData],
    ...allAvgData,
  ],
  x: 'x',
};

const resetSubchart = inst => inst.flush();
const handleReset = ref => resetSubchart(ref.current);

const AvgTrendLineChart = ({
  bindTo,
  visible,
}) => {
  const [setPoints/* , setSetPoints */] = useState([50, 60]);

  return (
    <Chart
      actionPoints={setPoints}
      bindTo={bindTo}
      data={ttemp}
      handleReset={handleReset}
      visible={visible}
    />
  );
};

export default AvgTrendLineChart;
