import React from 'react';
import PropTypes from 'prop-types';

const ArrowUp = ({ className, color, margin, rotate, size, strokeWidth }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      ...rotate && { transform: `rotate(${rotate}deg)` },
    }}
  >
    <line x1="12" y1="19" x2="12" y2="5"></line>
    <polyline points="5 12 12 5 19 12"></polyline>
  </svg>
);

export default ArrowUp;

ArrowUp.defaultProps = {
  className: null,
  color: 'rgb(0,0,0)',
  rotate: null,
  size: 16,
  strokeWidth: 2,
};

ArrowUp.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  rotate: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
