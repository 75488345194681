import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Textarea } from '../../../../../common/elements';
import Styled from './style';
import IrrigationTable from './irrigationTable';
import PawTable from './pawTable';

const initialNotes = [{
  id: 1,
  text: 'Grower says bad well.',
}];

const IrrigationSpecs = ({ deviceType, visible }) => {
  const [notes, setNotes] = useState(initialNotes);
  const [textareaValue, setTextareaValue] = useState('');

  const resetTextArea = () => {
    setTextareaValue('');
  };

  const handleTextAreaChange = e => {
    const { value } = e.target;

    if (!value) {
      setTextareaValue('');
    }

    return setTextareaValue(value);
  };

  const handleAddNote = () => {
    const newNote = {
      id: notes[notes.length - 1].id + 1,
      text: textareaValue,
    };

    setNotes([
      ...notes,
      newNote,
    ]);

    resetTextArea();
  };

  if (!visible) {
    return null;
  }

  return (
    <Styled>
      <IrrigationTable />
      <div className="divider" />
      <PawTable />
      <div className="divider" />
      <div className="irrigation-notes">
        <div style={{ color: 'rgb(0,0,0)', fontSize: '16px', marginBottom: '12px' }}>Notes</div>
        {notes.map(({ id, text }) => (
          <div className="irrigation-note" key={id}><span>&bull;</span> {text}</div>
        ))}
        <div className="textarea-container">
          <Textarea onChange={e => handleTextAreaChange(e)} rows="4" value={textareaValue} />
        </div>
        <div className="button-container">
          <Button disabled={textareaValue === ''} onClick={() => handleAddNote()} type="primary">Add Note</Button>
          <Button disabled={textareaValue === ''} onClick={() => resetTextArea()} type="default">Cancel</Button>
        </div>
      </div>
    </Styled>
  );
};

export default IrrigationSpecs;

IrrigationSpecs.defaultProps = {
  visible: true,
};

IrrigationSpecs.propTypes = {
  visible: PropTypes.bool,
};
