import styled from 'styled-components';

const Styled = styled.div`
  &.nested-card-container {
    padding: 0 8px 8px;
    :only-child {
      padding: 8px;
    }
    :first-child:not(:only-child) {
      padding: 8px;
    }
    :last-child:not(:only-child) {
      padding: 0 8px;
    }
    @media only screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      :only-child {
        padding-left: 0;
        padding-right: 0;
      }
      :first-child:not(:only-child) {
        padding-left: 0;
        padding-right: 0;
      }
      :last-child:not(:only-child) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .card {
    background: rgb(255,255,255);
    border-radius: 4px;
    margin-left: 8px;
    margin-bottom: 8px;
    &.nested {
      background: rgb(250,250,250);
      margin: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .card {
      border-radius: 0;
      margin: 8px 0;
    }
  }
  .card-title {
    background: rgb(255,255,255);
    border-radius: 4px;
    color: rgb(0,0,0);
    cursor: pointer;
    font-size: 16px;
    padding: 10px 8px;
    .card-title-header {
      align-items: center;
      display: flex;
      .card-title-wrapper {
        align-items: center;
        display: flex;
        &.bold {
          font-weight: 600;
        }
        .card-title-inline {
          display: flex;
          margin-left: 8px;
          svg {
            height: 26px;
            width: 48px;
          }
        }
      }
    }
    .card-title-footer-content {
      align-items: center;
      display: flex;
      font-size: 14px;
      padding-top: 4px;
      > div {
        margin-right: 8px;
        svg {
          height: 26px;
          width: 48px;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .card-title {
      border-radius: 0;
    }
  }
  .card-title .card-title-actions {
    align-items: center;
    display: flex;
    margin-left: auto;
  }
  .card-title .card-title-actions .link-divider {
    background: rgb(232,232,232);
    height: 20px;
    margin: 0 12px;
    width: 1px;
  }
  .card-title .card-title-actions .card-icon {
    transform: rotate(0deg);
  }
  .card-title .card-title-actions .card-icon.open {
    transform: rotate(90deg);
  }
  .card-content {
    background: rgb(250,250,250);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .card-content.hidden {
    display: none;
    padding: 0;
  }
  .card-tile-container {
    padding-top: 8px;
  }
`;

export default Styled;
