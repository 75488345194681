import React from 'react';
import PropTypes from 'prop-types';

const Camera = ({ className, color, rotate, size, strokeWidth }) => (
  <svg
    className={className}
    fill="none"
    height={size}
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rotate && { style: { transform: `rotate(${rotate}deg)` } }}
  >
    <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
    <circle cx="12" cy="13" r="4"></circle>
  </svg>
);

export default Camera;

Camera.defaultProps = {
  className: null,
  color: 'rgb(0,0,0)',
  rotate: null,
  size: 16,
  strokeWidth: 2,
};

Camera.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  rotate: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
