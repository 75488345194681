import styled from 'styled-components';

const Styled = styled.div`
  background: rgb(255,255,255);
  color: rgba(0,0,0,.85);
  min-height: 100%;
  padding: 32px;
  .title-wrapper {
    align-items: center;
    display: flex;
    font-size: 32px;
    .logo {
      img {
        height: 40px;
        margin-right: 16px;
      }
    }
  }
  .details-wrapper {
    font-size: 18px;
    padding: 16px 0 24px;
    > div {
      margin-bottom: 4px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
  .sample-image {
    margin-bottom: 16px;
    :last-child {
      margin-bottom: 0;
    }
    img {
      max-width: 85%;
    }
  }
  .sample-capture-modal {
    background: rgba(0,0,0,.45);
    bottom: 0;
    left: 0;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
    .sample-capture-modal-wrap {
      bottom: 0;
      left: 0;
      outline: 0;
      overflow: auto;
      padding: 48px;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1000;
      .inner {
        position: relative;
        &.loading-wrapper {
          height: 100%;
          .loading {
            align-items: center;
            color: rgb(255,255,255);
            display: flex;
            font-size: 20px;
            height: 100%;
            justify-content: center;
          }
        }
      }
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
    .close-icon {
      position: absolute;
      right: 28px;
      top: 28px;
      z-index: 1002;
    }
  }
`;

export default Styled;
