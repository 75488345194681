import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PAW_TABLE } from '../../../../../graphql/queries/soilMoisture';

const PawTable = () => {
  const { data, error, loading } = useQuery(GET_PAW_TABLE);

  if (loading || error) {
    return null;
  }

  const { getPawTable: { pawReadings } } = data;

  return (
    <>
      <div className="soil-type-table-heading">Plant Available Water Chart</div>
      <div className="soil-type-table">
        <div className="soil-type-table-header">
          <div>Sensor Depth</div>
          <div>Water Depleted (in/ft)</div>
          <div>Plant Available Water %</div>
          <div>Readily Available Water (in/ft)</div>
          <div>Soil Texture</div>
          <div>Sensor Reading</div>
        </div>
        {pawReadings.map(reading => (
          <div className="soil-type-table-row" key={reading.depth}>
            <div>{`${reading.depth}"`}</div>
            <div>{`${reading.depletedInPerFt} in`}</div>
            <div>{`${reading.pawRatio}%`}</div>
            <div>{`${reading.rawInPerFt} in`}</div>
            <div>{reading.soilTexture}</div>
            <div>{`${reading.sensorReading} SF`}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PawTable;
