import React, { useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Button } from '../common/elements';
import { Menu } from '../common/icons';
import ServiTechLogo from '../common/logos/servitech-logo.svg';
import { clearAccessToken } from '../auth/accessToken';
// import config from '../config';

// const { googleClientId } = config;

const Header = () => {
  const [redirect, setRedirect] = useState(false);

  const { pathname } = useLocation();

  const isCapture = !pathname.includes('login');

  const handleLogOut = async () => {
    clearAccessToken();
    setTimeout(() => {
      setRedirect(true);
    }, 1000);
  };

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <header {...isCapture && { style: { borderBottom: 'none', boxShadow: 'rgba(0,20,40,.08) 0 1px 3px 0' } }}>
      <div {...isCapture && { style: { width: '75px' } }}>
        <img alt="ServiTech" className="logo" src={ServiTechLogo} />
      </div>
      <div>
        {isCapture && <span style={{ color: 'rgba(0,0,0,.85)', fontSize: '20px' }}>Sample Capture STAGING</span>}
      </div>
      <div>
        {!isCapture && <Menu size={24} />}
        {isCapture && (
          <Button
            onClick={handleLogOut}
            type="mello"
          >
            Sign Out
          </Button>
        )}
      </div>
    </header>
  );
};

export default Header;
