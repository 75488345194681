import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_IRRIGATION_TABLE } from '../../../../../graphql/queries/soilMoisture';

const IrrigationTable = () => {
  const { data, error, loading } = useQuery(GET_IRRIGATION_TABLE);

  if (loading || error) {
    return null;
  }

  const { getIrrigationTable: { irrigationSpecs } } = data;

  return (
    <div className="irrigation-grid">
      <div>
        <div>Crop</div>
        <div className="irrigation-spec">
          {irrigationSpecs.crop || '-'}
        </div>
      </div>
      <div>
        <div>Growth Stage</div>
        <div className="irrigation-spec">
          {irrigationSpecs.growthStage || '-'}
        </div>
      </div>
      <div>
        <div>Soil Type</div>
        <div className="irrigation-spec">
          {irrigationSpecs.soilTexture || '-'}
        </div>
      </div>
      <div>
        <div>Irrigation Type</div>
        <div className="irrigation-spec">
          {irrigationSpecs.irrigationType || '-'}
        </div>
      </div>
      <div>
        <div>Field Size</div>
        <div className="irrigation-spec">
          {irrigationSpecs.area ? `${irrigationSpecs.area} acres` : '-'}
        </div>
      </div>
      <div>
        <div>Well Capacity</div>
        <div className="irrigation-spec">
          {irrigationSpecs.wellCapacity ? `${irrigationSpecs.wellCapacity} GPM` : '-'}
        </div>
      </div>
      <div>
        <div>Pivot Pass Amount</div>
        <div className="irrigation-spec">
          {irrigationSpecs.pivotPassAmount ? `${irrigationSpecs.pivotPassAmount} per pass` : '-'}
        </div>
      </div>
      <div>
        <div>Evapotranspiration (ET)</div>
        <div className="irrigation-spec">
          {irrigationSpecs.et ? `${Math.round((Number(irrigationSpecs.et) + Number.EPSILON) * 100) / 100}"` : '-'}
        </div>
      </div>
    </div>
  );
};

export default IrrigationTable;
