import styled from 'styled-components';

const Styled = styled.div`
  .irrigation-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .irrigation-notes {
    .irrigation-note {
      align-items: center;
      display: flex;
      margin: 8px 0;
      span {
        margin-right: 4px;
      }
    }
    .textarea-container {
      display: flex;
      margin: 16px 0;
      textarea {
        width: 100%;
      }
    }
    .button-container {
      display: flex;
      > button {
        :first-child {
          margin-right: 16px;
        }
      }
    }
  }
  .irrigation-spec {
    color: rgb(0,0,0);
    font-size: 16px;
  }
  .irrigation-spec-toggle {
    color: rgb(0,122,255);
    font-size: 12px;
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .soil-type-table-heading {
    color: rgb(0,0,0);
    font-size: 16px;
    margin-bottom: 16px;
  }
  .soil-type-table {
    .soil-type-table-header,
    .soil-type-table-row {
      display: flex;
      > div {
        padding: 8px;
        width: 16.667%;
      }
    }
    .soil-type-table-header {
      background: rgb(250,250,250);
      color: rgb(0,0,0,.85);
      font-weight: 600;
    }
    .soil-type-table-row {
      :nth-child(odd) {
        background: rgb(250,250,250);
      }
    }
  }
`;

export default Styled;
