import React from 'react';
import PropTypes from 'prop-types';
import Styled from './style';

const Textarea = ({ onChange, rows, value }) => (
  <Styled
    onChange={e => onChange(e)}
    rows={rows}
    style={{ minHeight: '160px' }}
    value={value}
  />
);

export default Textarea;

Textarea.defaultProps = {
  onChange: null,
  rows: '8',
  value: '',
};

Textarea.propTypes = {
  onChange: PropTypes.func,
  rows: PropTypes.string,
  value: PropTypes.string,
};
