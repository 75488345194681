import React from 'react';
import PropTypes from 'prop-types';
import { Close, CloseCircle } from '../../../common/icons';

const ErrorModal = ({ errors, handleCloseError }) => (
  <div className="error">
    <div
      aria-hidden="true"
      className="title-wrapper"
      onClick={() => handleCloseError()}
    >
      <CloseCircle
        color="rgb(255,77,79)"
        lineColor="rgb(255,77,79)"
        size={24}
      />
      <div>Error</div>
      <Close
        color="rgba(0,0,0,.65)"
        size={18}
      />
    </div>
    <ul>
      {Object.entries(errors).map(el => el[1].message
        && <li key={el[1].message}>{el[1].message}</li>)}
    </ul>
  </div>
);

export default ErrorModal;

ErrorModal.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  handleCloseError: PropTypes.func.isRequired,
};
