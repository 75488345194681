import React, { useEffect, useState, } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
const listItems = [
    'All',
    'Feed',
    'Fertilizer',
    'Plant',
    'Soil',
    'Environmental',
    'Other'
];

const SampleTypeFilterModal = (props) => {
    const {
        filter,
        filterList,
        isOpen,
        setModalOpen,
        handleFiltering
    } = props;

    const [taskState, setTaskState] = useState(filterList)

    const handleOnHide = () => {
        setModalOpen(!isOpen);
        filter("")
    }

    const handleSave = () => {
        setModalOpen(!isOpen);
    }

    const isChecked = (id) => {
        return (taskState.indexOf(id.toString()) > -1);
    }

    const handleFormUpdate = (event) => {
        if (event.target.value === 'All') {
            filter("")
        } else {
            filter(event.target.value);
        }
    }

    useEffect(() => {
        if(filterList === "") {
            setTaskState("All")
        } else {
            setTaskState(filterList)
        }
    }, [filterList]);

    return (
        <Modal
            show={isOpen}
            onHide={handleOnHide}
            size="sm"
            centered
            backdrop="static"
        >
            <Modal.Header><h6>Filter by Sample Type</h6></Modal.Header>
            <div>
                <Modal.Body className="device-filter-modal">
                    {listItems.map((row, index) =>
                        <Form.Check
                            key={index}
                            type="radio"
                            label={row}
                            name="taskState"
                            id={'taskStaterow' + index}
                            value={row}
                            onChange={handleFormUpdate}
                            checked={isChecked(row)}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="light" onClick={handleOnHide}>Cancel</Button> */}
                    <Button variant="primary" onClick={(e) => {handleSave(); handleFiltering();}}>Apply</Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

export default SampleTypeFilterModal;