import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import bb, { bar, line, subchart } from 'billboard.js';
import 'billboard.js/dist/billboard.css';
import { Button } from '../../../../../../common/elements';

const Chart = ({ actionPoints, bindTo, data, deviceType, handleReset, visible }) => {
  const ref = useRef(null);
  const actionRef = useRef(null);

  useEffect(() => {
    const renderChart = () => {
      ref.current = bb.generate({
        bar: {
          width: 40,
        },
        axis: {
          x: {
            tick: {
              centered: true,
              count: 8,
              format: '%-m/%-d',
              outer: false,
            },
            type: 'timeseries',
          },
          y: {
            label: {
              position: 'outer-middle',
              text: deviceType === 'Watermark Sensor' ? 'Plant Available Water (%)' : 'Scaled Frequency (SF)',
            },
            max: 100,
            min: 0,
            tick: {
              outer: false,
              show: false,
              values: [0, 20, 40, 60, 80, 100],
            },
          },
          y2: {
            label: {
              position: 'outer-middle',
              text: 'Rain (in)',
            },
            max: 0.5,
            min: 0,
            tick: {
              outer: false,
              show: false,
              values: [0, 0.1, 0.2, 0.3, 0.4, 0.5],
            },
            show: true,
          },
        },
        bindto: `#${bindTo}`,
        data: { ...data, types: { 'Rain (Daily)': bar() } },
        grid: {
          y: {
            lines: [...actionPoints.map(el => ({ value: Number(el) }))],
            show: true,
          },
        },
        legend: {
          item: {
            onclick: x => ref.current.toggle(x),
            onout: () => { },
            onover: () => { },
          },
        },
        padding: {
          left: 64,
          right: 64,
        },
        point: {
          show: false,
        },
        size: {
          height: 432,
        },
        subchart: {
          axis: {
            x: {
              show: true,
              tick: {
                show: true,
                text: {
                  show: false,
                },
              },
            },
          },
          show: subchart(),
        },
        tooltip: {
          format: {
            title: d => {
              const options = {
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                month: 'numeric',
                year: '2-digit',
              };

              const formatted = new Intl.DateTimeFormat('en-US', options).format(d);
              return formatted;
            },
            value: value => {
              if (value < 10) {
                return `${value}"`;
              }
              return value.toFixed(2);
            },
          },
        },
        type: line(),
      });
    };

    // guard against uneeded re-renders
    if (visible
      && (actionRef && actionRef.current ? actionRef.current[0] : '') !== actionPoints[0]
      && (actionRef && actionRef.current ? actionRef.current[1] : '') !== actionPoints[1]) {
      actionRef.current = actionPoints;
      renderChart();
    }
  }, [actionPoints, bindTo, data, deviceType, visible]);

  return (
    <div className="soil-moisture-chart-style">
      <Button
        className="chart-reset-button"
        onClick={() => handleReset(ref)}
        size="small"
        type="default"
      >
        Reset Zoom
      </Button>
      <div id={bindTo} />
    </div>
  );
};

export default Chart;

Chart.defaultProps = {
  visible: true,
};

Chart.propTypes = {
  bindTo: PropTypes.string.isRequired,
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.array.isRequired,
    ).isRequired,
    x: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool,
};
