import React from 'react';
import PropTypes from 'prop-types';
import Styled from './style';
import { Input } from '../../../../../common/elements';

const IrrigationCalculator = ({
  acreInches,
  daysToApplyIrrigation,
  handleCalculatorInputChange,
  irrigatedAcres,
  visible,
  wellCapacity,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Styled>
      <div className="irrigation-calc-container">
        <div>
          <div className="irrigation-calc-label">Irrigated Acres</div>
          <div>
            <Input
              onChange={e => handleCalculatorInputChange(e, 'irrigatedAcres')}
              value={irrigatedAcres || ''}
            />
          </div>
        </div>
        <div>
          <div className="irrigation-calc-label">Well Capacity (GPM)</div>
          <div>
            <Input
              onChange={e => handleCalculatorInputChange(e, 'wellCapacity')}
              value={wellCapacity || ''}
            />
          </div>
        </div>
        <div>
          <div className="irrigation-calc-label">Acre-inches</div>
          <div>
            <Input
              onChange={e => handleCalculatorInputChange(e, 'acreInches')}
              value={acreInches || ''}
            />
          </div>
        </div>
        <div>
          <div className="irrigation-calc-label">Days to Apply Irrigation</div>
          <div className="irrigation-calc-days">
            {`${daysToApplyIrrigation} days`}
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default IrrigationCalculator;

IrrigationCalculator.defaultProps = {
  visible: true,
};

IrrigationCalculator.propTypes = {
  acreInches: PropTypes.string.isRequired,
  daysToApplyIrrigation: PropTypes.string.isRequired,
  handleCalculatorInputChange: PropTypes.func.isRequired,
  irrigatedAcres: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  wellCapacity: PropTypes.string.isRequired,
};
