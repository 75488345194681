import styled from 'styled-components';

const Styled = styled.div`
  &.tile {
    background: rgb(255,255,255);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 8px;
  }
  &.tile:not(.row):not(:last-child) {
    margin-bottom: 8px;
  }
  @media only screen and (max-width: 767px) {
    &.tile {
      border-radius: 0;
    }
  }
  .tile-bold,
  .tile-heading  {
    color: rgb(0,0,0);
    font-size: 16px;
  }
  .tile-heading {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 48px;
    margin: -8px;
    padding: 0 8px;
  }
  .tile-heading.expanded {
    margin-bottom: 0;
  }
  .tile-heading .icon {
    display: flex;
  }
  @media only screen and (max-width: 767px) {
    .tile-heading.transparent {
      padding-left: 8px;
    }
  }
  .tile-divider {
    background: rgb(232,232,232);
    height: 1px;
    margin: 16px 0;
    width: 100%;
  }
  .tile-flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .tile-note {
    margin: 16px 0;
  }
  .tile-note.single {
    margin: 0;
  }
  .tile-note:not(:last-child) {
    margin-bottom: 0;
  }
  .tile-note .tile-note-bold {
    color: rgb(0,0,0);
  }
  .tile-note .tile-note-owner {
    margin-bottom: 8px;
  }
  .tile-summary-container {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .tile-summary {
    text-align: center;
  }
  .tile-summary-label {
    border: solid 1px rgb(232,232,232);
    padding: 4px;
  }
  .tile-summary-data {
    border: solid 1px rgb(232,232,232);
    border-top: none;
    color: rgba(0,0,0,.85);
    font-size: 16px;
    padding: 4px;
  }
  .tile-weather {
    align-items: center;
    display: flex;
  }
  .tile-weather .tile-icon {
    margin-right: 16px;
  }
  .tile-weather .tile-icon svg {
    height: 40px;
    width: 40px;
  }
`;

export default Styled;
