import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from '../../common/icons';
import Styled from './style';

const Card = ({ children, nested, nestedStyle, titleConfig }) => {
  const { cardOpen, footer: Footer, onClick, title } = titleConfig;
  const { inlineElement: TitleInlineElement } = title;

  return (
    <Styled
      {...nested && { className: 'nested-card-container' }}
      {...nestedStyle && { style: nestedStyle }}
    >
      <div className={nested ? 'card nested' : 'card'}>
        {/* include title if config is given */}
        {onClick && title.text ? (
          <>
            <div
              aria-hidden="true"
              className="card-title"
              onClick={() => onClick()}
            >
              <div className="card-title-header">
                <div className={title.bold ? 'card-title-wrapper bold' : 'card-title-wrapper'}>
                  {title.text}
                  {TitleInlineElement && (
                    <span className="card-title-inline">
                      <TitleInlineElement />
                    </span>
                  )}
                </div>
                <div className="card-title-actions">
                  <ChevronRight className={cardOpen ? 'card-icon open' : 'card-icon'} />
                </div>
              </div>
              {Footer && <Footer />}
            </div>
            <div className={cardOpen ? 'card-content' : 'card-content hidden'}>
              {children}
            </div>
          </>
        ) : (children)}
      </div>
    </Styled>
  );
};

export default Card;

Card.defaultProps = {
  nested: false,
  nestedStyle: {},
  titleConfig: {
    cardOpen: false,
    footer: null,
    onClick: null,
    title: {
      bold: false,
      inlineElement: null,
      text: null,
    },
  },
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  nested: PropTypes.bool,
  nestedStyle: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  titleConfig: PropTypes.shape({
    cardOpen: PropTypes.bool,
    footer: PropTypes.func,
    onClick: PropTypes.func,
    title: PropTypes.shape({
      bold: PropTypes.bool,
      inlineElement: PropTypes.func,
      text: PropTypes.string,
    }),
  }),
};
