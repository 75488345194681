import { gql } from '@apollo/client';

export const GET_IRRIGATION_TABLE = gql`
  query GetIrrigationTable {
    getIrrigationTable {
      irrigationSpecs {
        area
        crop
        et
        growthStage
        irrigationType
        pivotPassAmount
        soilTexture
        wellCapacity
      }
    }
  }
`;

export const GET_PAW_TABLE = gql`
  query GetPawTable {
    getPawTable {
      pawReadings {
        depth
        depletedInPerFt
        pawRatio
        rawInPerFt
        sensorReading
        soilTexture
      }
    }
  }
`;
