import React, { useEffect, useState } from 'react';
import { setAccessToken } from './auth/accessToken';
import PublicRouter from './publicRouter';
import config from './config';

const { fetchUrl } = config;

const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(fetchUrl, {
            credentials: 'omit',
            method: 'POST',
        }).then(async x => {
            const { accessToken } = await x.json();
            setAccessToken(accessToken);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div>loading...</div>;
    }

    return <PublicRouter />;
};

export default App;
