import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_AGRONOMIST_GROWER_LIST = gql`
  query GetAgronomistGrowerList($agronomistId: String!) {
    getAgronomistGrowerList(agronomistId: $agronomistId) {
      id
      firstName
      lastName
      growers {
        id
        firstName
        lastName
        fields {
          id
          fieldXId
          legacyId
          name
        }
      }
    }
  }
`;
