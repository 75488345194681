const dev = {
  bugsnagKey: '',
  domain: 'http://localhost:3000',
  fetchUrl: 'https://gql-sample-capture-bjudfyf5za-uc.a.run.app/refresh_token',
  googleClientId: '495656309447-taph324qeq87gu34de640oshvptqgkfr.apps.googleusercontent.com',
  uri: 'https://gql-sample-capture-staging-bjudfyf5za-uc.a.run.app/graphql'
  // uri:'http://localhost:4000/graphql'
};
const stage = {
  bugsnagKey: '10cdd9b855c1017f156478cc0c86be8b',
  domain: 'https://sample-capture-staging-bjudfyf5za-uc.a.run.app',
  fetchURL: 'https://gql-sample-capture-bjudfyf5za-uc.a.run.app/refresh_token',
  googleClientId: '495656309447-taph324qeq87gu34de640oshvptqgkfr.apps.googleusercontent.com',
  uri: 'https://gql-sample-capture-staging-bjudfyf5za-uc.a.run.app/graphql'
  // uri:'http://localhost:4000/graphql'
};
const prod = {
  bugsnagKey: '10cdd9b855c1017f156478cc0c86be8b',
  domain: 'https://sample-capture-staging-bjudfyf5za-uc.a.run.app',
  fetchUrl: 'https://gql-sample-capture-bjudfyf5za-uc.a.run.app/refresh_token',
  googleClientId: '495656309447-taph324qeq87gu34de640oshvptqgkfr.apps.googleusercontent.com',
  uri: 'https://gql-sample-capture-staging-bjudfyf5za-uc.a.run.app/graphql', //uri when deploying to gcr.io/servitech-306319/sample-capture-staging
  // uri:'http://localhost:4000/graphql'
};

let config = dev;
// eslint-disable-next-line
switch (process.env.REACT_APP_STAGE) {
  case 'prod':
  case 'production':
    config = prod
    break;

  case 'stage':
  case 'staging':
    config = stage
    break;
}
// const config = process.env.REACT_APP_STAGE === 'prod'
//   ? prod
//   : dev;

export default {
  pageSize: 20,
  ...config,
};
