import { ApolloClient, ApolloLink, InMemoryCache, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from './auth/accessToken';
import config from './config';

const { fetchUrl, uri } = config;

const cache = new InMemoryCache({
    addTypename: false,
});

const requestLink = new ApolloLink(
    (operation, forward) => new Observable(observer => {
        let handle;
        Promise.resolve(operation)
            // eslint-disable-next-line no-shadow
            .then(operation => {
                const accessToken = getAccessToken();

                if (accessToken) {
                    operation.setContext({
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
            })
            .then(() => {
                handle = forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                });
            })
            .catch(observer.error.bind(observer));

        return () => {
            if (handle) {
                handle.unsubscribe();
            }
        };
    }),
);

const httpUploadLink = createUploadLink({
    credentials: 'omit',
    uri,
});

const client = new ApolloClient({
    link: ApolloLink.from([
        new TokenRefreshLink({
            accessTokenField: 'accessToken',
            isTokenValidOrUndefined: () => {
                const token = getAccessToken();
                
                if (!token) {
                    return false;
                }

                try {
                    const { exp } = jwtDecode(token);
                    if (Date.now() >= exp * 1000) {
                        return false;
                    }
                    return true;
                } catch {
                    return false;
                }
            },
            fetchAccessToken: () => fetch(fetchUrl, {
                method: 'POST',
                credentials: 'omit',
            }),
            handleFetch: accessToken => {
                setAccessToken(accessToken);
            },
            handleError: () => {
                client.resetStore();
            },
        }),
        onError((error) => {
            client.resetStore();

            throw error;
        }),
        requestLink,
        httpUploadLink,
    ]),
    cache,
});

export default client;
