/* mobile breakpoint = 767px */
/* tablet breakpoint = 1024px */
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html {
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  body {
    background: rgb(240,242,244);
    color: rgba(0,0,0,.65);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  header {
    align-items: center;
    background: rgb(255,255,255);
    border-bottom: 1px solid rgb(232,232,232);
    /* box-shadow: rgba(0,20,40,.08) 0 1px 3px 0; */
    display: flex;
    height: 64px;
    justify-content: space-between;
    left: 0;
    padding: 0 16px;
    position: fixed;
    right: 0;
    z-index: 2;
  }
  header > div {
    display: flex;
  }
  header .logo {
    height: 40px;
  }
  header .logo .st0 {
    fill:#E23040;
  }
  header .logo .st1 {
    fill:#1F63AC;
  }
  .st0 {
    fill:#E23040;
  }
  .st1 {
    fill:#1F63AC;
  }


  /* remove once all buttons are moved to element */
  button {
    align-items: center;
    appearance: none;
    border: solid 1px rgb(0,122,255);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    height: 32px;
    outline: none!important;
    padding: 0 16px;
  }
  button:focus {
    outline: none;
  }
  button.primary {
    background: rgb(0,122,255);
    color: rgb(255,255,255);
  }
  button.ghost {
    background: transparent;
    color: rgb(0,122,255);
  }
  button.default {
    background: rgb(255,255,255);
    border-color: rgb(232,232,232);
    color: rgba(0,0,0,.65);
  }
  button.small {
    height: 28px;
  }
  button.link {
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    color: rgb(0,122,255);
    display: flex;
    height: unset;
    padding: 0;
  }
  /* end remove once all buttons are moved to element */



  a {
    color: rgb(0,122,255);
    text-decoration: none;
  }
  /* grid */
  .grid {
    display: grid;
    grid-gap: 16px;
  }
  .grid.two-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid.three-col {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid.four-col {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid.six-col {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid.eight-col {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  @media only screen and (max-width: 767px) {
    .grid.two-col,
    .grid.three-col {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .grid.four-col,
    .grid.six-col,
    .grid.eight-col {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  /* end grid */
  .content {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding-top: 64px;
  }
  .top-action-container {
    align-items: center;
    background: rgb(255,255,255);
    /* border-bottom: 1px solid rgb(232,232,232); */
    box-shadow: rgba(0,20,40,.08) 0 1px 3px 0;
    display: flex;
    height: 56px;
    justify-content: space-between;
    padding: 0 16px;
    position: fixed;
    width: 100%;
    z-index: 1;
  }
  .top-action-heading {
    align-items: center;
    color: rgba(0,0,0,.85);
    display: flex;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .top-button-container {
    display: flex;
    justify-content: flex-end;
    margin: 72px 32px 0 32px;
  }
  @media only screen and (max-width: 767px) {
    .top-button-container {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  .top-button-container button:first-child:not(:last-child) {
    margin-right: 8px;
  }


  /* divider */
  .divider {
    background: rgb(232,232,232);
    height: 1px;
    margin: 16px 0;
    width: 100%;
  }


  /* select */
  select {
    appearance: none;
    /* background: rgb(250,250,250) url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' width='14' height='14' stroke='rgb(0,0,0)' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' xmlns='http://www.w3.org/2000/svg'><polyline points='6 9 12 15 18 9'></polyline></svg>") no-repeat; */
    background: rgb(255,255,255) url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' width='14' height='14' stroke='rgb(0,0,0)' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' xmlns='http://www.w3.org/2000/svg'><polyline points='6 9 12 15 18 9'></polyline></svg>") no-repeat;
    background-position: right 5px top 50%;
    /* border: 1px solid rgb(232,232,232); */
    border: 1px solid rgb(216,216,216);
    border-radius: 4px;
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    height: 32px;
    padding: 6px 8px;
  }
  select:focus {
    outline: 0;
  }
  /* end select */
  /* table */
  table {
    border-spacing: 0;
    text-align: left;
    width: 100%;
  }
  table thead > tr > th,
  table tbody> tr > td {
    border-bottom: 1px solid rgb(240,240,240);
    padding: 16px;
    white-space: nowrap;
  }
  table thead > tr > th {
    background: rgb(250, 250, 250);
    color: rgb(0, 0, 0);
    font-weight: 400;
  }
  /* end table */
  /* loader */
  .loader-container {
    display: flex;
    width: 100%;
  }
  .loader {
    animation: loader-rotate .8s infinite linear;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top: 2px solid rgb(232,232,232);
    clear: both;
    height: 32px;
    margin: 16px auto;
    width: 32px;
  }
  @keyframes loader-rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
  }
  /* end loader */
  /* error */
  .error-container {
    align-items: center;
    background: rgb(255,255,255);
    display: flex;
    flex-direction: column;
    padding: 32px 16px 20px;
    text-align: center;
  }
  /* end error */
  .action-pills {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 16px;
    &.bridge-nav {
      margin: 8px auto;
      overflow-x: scroll;
      white-space: nowrap;
      width: 100%;
      @media only screen and (max-width: 881px) {
        border-radius: 4px;
        justify-content: flex-start;
        margin: 8px;
        width: calc(100% - 16px);
      }
    }
    > div {
      align-items: center;
      background: rgb(255,255,255);
      display: flex;
      height: 40px;
      justify-content: center;
      padding: 0 16px;
      &.selected {
        background: rgb(0,122,255);
        color: rgb(255,255,255);
      }
      :first-child {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
      :last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }
  /* chart specific */
  .bb svg, .bb-legend-item {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
  }
  .bb-axis, .bb text {
    fill: rgb(96,96,96);
  }
  .bb path, .bb line {
    stroke: rgb(96,96,96);
  }
  .bb-tooltip {
    opacity: 1;
  }
  line.bb-ygrid {
    stroke: rgb(232,232,232);
    stroke-dasharray: 0;
  }

  .bb-axis-y-label,
  .bb-axis-y2-label {
    font-size: 12px;
  }
  .bb-axis-y path.domain,
  .bb-axis-y2 path.domain {
    display: none;
  }
  .bb-legend-item-hidden {
    opacity: .6;
  }
  .bb-ygrid-line {
    line {
      stroke: rgb(255,0,0);
      stroke-dasharray: 5;
      stroke-width: 2;
    }
  }
  /* end chart specific */
`;

export default GlobalStyle;
