import styled from 'styled-components';

const Styled = styled.textarea`
  appearance: none;
  border: solid 1px rgb(216,216,216);
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  line-height: 1.5715;
  outline: none!important;
  padding: 4px 12px;
`;

export default Styled;
