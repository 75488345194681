import React from 'react';
import { Modal, Button } from 'react-bootstrap';
const DeleteModal = (props) => {

    const {
        isOpen,
        setShowDeleteModal,
        handlePhotoDelete
    } = props;
    const handleOnHide = () => {
        setShowDeleteModal(!isOpen);
    }
    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen}
            onHide={handleOnHide}
        >
            <div className="rename-file-area">
                <Modal.Body className="rename-file-modal-body">
                <h1 style={{textAlign:'center'}}>
                        ARE YOU SURE YOU WANT TO DELETE THIS IMAGE?
                    </h1>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" size="sm" onClick={(e) => {handlePhotoDelete(e)}}>DELETE</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button variant="light" size="sm" onClick={e => handleOnHide(e)}>CANCEL</Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};
export default DeleteModal;