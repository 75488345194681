import styled from 'styled-components';

export const StyledInput = styled.input`
  :not([type='checkbox']) {
    appearance: none;
    background-color: rgb(255,255,255);
    background-image: none;
    border: 1px solid rgb(216,216,216);
    border-radius: 4px;
    box-sizing: border-box;
    color: rgba(0,0,0,.85);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    line-height: 1.5715;
    min-width: 0;
    padding: 4px 12px;
    width: 100%;
    &.input-inline {
      margin: 4px 2px 4px 8px;
      text-align: center;
      width: 64px;
      &.date {
        width: 96px;
      }
    }
    &.calculator-inline {
      margin: 4px 2px 4px 0;
      text-align: center;
      width: 64px;
    }
    &.has-error {
      border-color: rgb(255,77,79);
      :focus {
        border-color: rgb(255,77,79);
        box-shadow: 0 0 0 2px rgba(255,77,79,.2);
        outline: 0;
      }
    }
  }
  :focus {
    border-color: rgb(0,122,255);
    box-shadow: 0 0 0 2px rgba(131,192,253,.5);
    outline: 0;
  }
  ::placeholder {
    color: rgb(192,192,192);
  }
`;

export const StyledInputLabel = styled.div`
  .label-wrapper {
    &.has-error {
      label {
        color: rgb(255,77,79);
      }
    }
    &.required {
      :after {
        color: rgb(255,77,79);
        content: '*';
        margin-left: 2px;
      }
    }
  }
  .label-preselect-wrapper {
    align-items: center;
    display: flex;
    .label-preselect {
      margin-right: 8px;
    }
  }
`;
